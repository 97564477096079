import { ease } from '@/animations/easings';
import gsap from 'gsap';

export const indexEnter = (container: HTMLElement) => {
    const clipElements = Array.from(container.querySelectorAll<HTMLElement>('.a-clip-element'));
    const decorElements = Array.from(container.querySelectorAll<HTMLElement>('.a-index-decor'));

    gsap.timeline({ defaults: { ease, duration: 0.8 } })
        .fromTo(
            clipElements,
            {
                y: '105%',
                stagger: 0.05,
            },
            {
                y: 0,
            },
        )
        .fromTo(
            decorElements,
            {
                scale: 0,
                stagger: 0.05,
                duration: 1,
            },
            {
                scale: 1,
            },
            0.1,
        );
};

export const indexLeave = (container: HTMLElement, resolve: (value?: unknown) => void) => {
    const clipElements = Array.from(container.querySelectorAll<HTMLElement>('.a-clip-element'));
    const decorElements = Array.from(container.querySelectorAll<HTMLElement>('.a-index-decor'));
    gsap.timeline({ defaults: { ease, duration: 0.8 } })
        .to(clipElements, {
            y: '-105%',
            stagger: 0.05,
        })
        .to(
            decorElements,
            {
                scale: 0,
                stagger: 0.05,
                duration: 1,
            },
            0.1,
        )
        .then(resolve);
};
