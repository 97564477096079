import { ease } from '@/animations/easings';
import gsap from 'gsap';
import debounce from 'lodash.debounce';

const mapSvg = document.querySelector<SVGElement>('.js-map-svg');
const mapWrapper = document.querySelector<HTMLElement>('.js-map-wrapper');
const mapCities = document.querySelector('.js-cities');
const isMobile = window.matchMedia('(max-width: 1279px)').matches;

const checkMapState = debounce(() => {
    const isMobile = window.matchMedia('(max-width: 1279px)').matches;
    gsap.to(mapSvg, {
        scale: isMobile
            ? window.matchMedia('(max-width: 767px)').matches
                ? 4
                : window.matchMedia('(max-width: 1023px)').matches
                ? 3
                : 1.75
            : 1,
    });
}, 50);

export const mapEnter = (container: HTMLElement) => {
    const backButton = container.querySelector<HTMLElement>('.a-header-back');
    const headerCenter = container.querySelector<HTMLElement>('.a-header-center');
    const scrollNotification = container.querySelector<HTMLElement>('.a-scroll-notification');
    const videoButton = container.querySelector<HTMLElement>('.a-video-button');

    if (backButton && headerCenter && videoButton) {
        const backButtonContent = Array.from(backButton.querySelectorAll<HTMLElement>('.a-header-back-content'));
        const headerCenterContent = Array.from(headerCenter.querySelectorAll<HTMLElement>('.a-header-center-content'));
        const videoButtonContent = Array.from(videoButton.querySelectorAll<HTMLElement>('.a-video-button-content'));

        const videoButtonRect = videoButton.getBoundingClientRect();
        const backButtonRect = backButton.getBoundingClientRect();
        const headerCenterRect = headerCenter.getBoundingClientRect();

        gsap.set([backButton, headerCenter, videoButton], { opacity: 1 });

        const tl = gsap
            .timeline({ defaults: { ease, duration: 0.67 } })
            .fromTo(
                backButton,
                {
                    width: 0,
                    height: 0,
                },
                {
                    width: backButtonRect.width,
                    height: backButtonRect.height,
                    onComplete: () => {
                        gsap.set(backButton, {
                            clearProps: 'height,width',
                        });
                    },
                },
                0,
            )
            .fromTo(
                backButtonContent,
                {
                    y: '-105%',
                    stagger: 0.05,
                },
                {
                    y: 0,
                    stagger: 0.05,
                },
                0.67,
            )
            .fromTo(
                videoButton,
                {
                    width: 0,
                    height: 0,
                    minWidth: 'unset',
                },
                {
                    width: videoButtonRect.width,
                    height: videoButtonRect.height,
                    onComplete: () => {
                        gsap.set(videoButton, {
                            minWidth: '',
                            clearProps: 'width,height',
                        });
                    },
                },
                0,
            )
            .fromTo(
                videoButtonContent,
                {
                    y: '-205%',
                    stagger: 0.05,
                },
                {
                    y: 0,
                    stagger: 0.05,
                },
                0.67,
            )
            .fromTo(
                headerCenter,
                {
                    width: 0,
                    height: 0,
                    minWidth: 'unset',
                },
                {
                    width: headerCenterRect.width,
                    height: headerCenterRect.height,
                    onComplete: () => {
                        gsap.set(headerCenter, {
                            minWidth: '',
                            clearProps: 'width,height',
                        });
                    },
                },
                0,
            )
            .fromTo(
                headerCenterContent,
                {
                    y: '-105%',
                    stagger: 0.05,
                },
                {
                    y: 0,
                    stagger: 0.05,
                },
                0.67,
            )
            .set(
                mapSvg,
                {
                    scale: isMobile
                        ? window.matchMedia('(max-width: 767px)').matches
                            ? 4
                            : window.matchMedia('(max-width: 1023px)').matches
                            ? 3
                            : 1.75
                        : undefined,
                },
                0,
            )
            .add(() => {
                if (mapSvg && mapWrapper) {
                    gsap.set(mapWrapper, {
                        scrollTo: {
                            x:
                                mapSvg && mapWrapper
                                    ? mapSvg.getBoundingClientRect().width / 2 - mapWrapper.offsetWidth / 2
                                    : 0,
                        },
                    });
                }
            }, 0.1)
            .fromTo(
                mapSvg,
                {
                    opacity: isMobile ? 0 : 1,
                },
                {
                    opacity: 1,
                },
                0.3,
            )
            .fromTo(
                mapCities,
                {
                    opacity: 0,
                },
                {
                    opacity: 1,
                },
                0.7,
            )
            .fromTo(
                scrollNotification,
                {
                    opacity: 0,
                },
                {
                    opacity: 1,
                },
            );
    }

    window.addEventListener('resize', checkMapState);
};

export const mapEnterFromLanding = (container: HTMLElement) => {
    const backButton = container.querySelector<HTMLElement>('.a-header-back');
    const headerCenter = container.querySelector<HTMLElement>('.a-header-center');
    const scrollNotification = container.querySelector<HTMLElement>('.a-scroll-notification');
    const videoButton = container.querySelector<HTMLElement>('.a-video-button');

    if (backButton && headerCenter && videoButton) {
        const backButtonContent = Array.from(backButton.querySelectorAll<HTMLElement>('.a-header-back-content'));
        const headerCenterContent = Array.from(headerCenter.querySelectorAll<HTMLElement>('.a-header-center-content'));
        const videoButtonContent = Array.from(videoButton.querySelectorAll<HTMLElement>('.a-video-button-content'));

        const videoButtonRect = videoButton.getBoundingClientRect();
        const backButtonRect = backButton.getBoundingClientRect();
        const headerCenterRect = headerCenter.getBoundingClientRect();

        gsap.set([backButton, headerCenter, videoButton], { opacity: 1 });

        gsap.timeline({ defaults: { ease, duration: 0.67 } })
            .fromTo(
                backButton,
                {
                    width: 0,
                    height: 0,
                },
                {
                    width: backButtonRect.width,
                    height: backButtonRect.height,
                    onComplete: () => {
                        gsap.set(backButton, {
                            clearProps: 'height,width',
                        });
                    },
                },
                0,
            )
            .fromTo(
                backButtonContent,
                {
                    y: '-105%',
                    stagger: 0.05,
                },
                {
                    y: 0,
                    stagger: 0.05,
                },
                0.67,
            )
            .fromTo(
                headerCenter,
                {
                    width: 0,
                    height: 0,
                    minWidth: 'unset',
                },
                {
                    width: headerCenterRect.width,
                    height: headerCenterRect.height,
                    onComplete: () => {
                        gsap.set(headerCenter, {
                            minWidth: '',
                            clearProps: 'width,height',
                        });
                    },
                },
                0,
            )
            .fromTo(
                headerCenterContent,
                {
                    y: '-105%',
                    stagger: 0.05,
                },
                {
                    y: 0,
                    stagger: 0.05,
                },
                0.67,
            )
            .fromTo(
                videoButton,
                {
                    width: 0,
                    height: 0,
                    minWidth: 'unset',
                },
                {
                    width: videoButtonRect.width,
                    height: videoButtonRect.height,
                    onComplete: () => {
                        gsap.set(videoButton, {
                            minWidth: '',
                            clearProps: 'width,height',
                        });
                    },
                },
                0,
            )
            .fromTo(
                videoButtonContent,
                {
                    y: '-105%',
                    stagger: 0.05,
                },
                {
                    y: 0,
                    stagger: 0.05,
                },
                0.67,
            )
            .set(
                mapSvg,
                {
                    scale: isMobile
                        ? window.matchMedia('(max-width: 767px)').matches
                            ? 4
                            : window.matchMedia('(max-width: 1023px)').matches
                            ? 3
                            : 1.75
                        : undefined,
                },
                0,
            )
            .add(() => {
                if (mapSvg && mapWrapper) {
                    gsap.set(mapWrapper, {
                        scrollTo: {
                            x:
                                mapSvg && mapWrapper
                                    ? mapSvg.getBoundingClientRect().width / 2 - mapWrapper.offsetWidth / 2
                                    : 0,
                        },
                    });
                }
            }, 0.1)
            .fromTo(
                mapSvg,
                {
                    opacity: isMobile ? 0 : 1,
                },
                {
                    opacity: 1,
                },
                0.67,
            )
            .fromTo(
                mapCities,
                {
                    opacity: 0,
                },
                {
                    opacity: 1,
                },
                0.7,
            )
            .fromTo(
                scrollNotification,
                {
                    opacity: 0,
                },
                {
                    opacity: 1,
                },
            );
    }

    window.addEventListener('resize', checkMapState);
};

export const mapLeaveToIndex = (container: HTMLElement, resolve: (value?: unknown) => void) => {
    const backButton = container.querySelector<HTMLElement>('.a-header-back');
    const headerCenter = container.querySelector<HTMLElement>('.a-header-center');
    const scrollNotification = container.querySelector<HTMLElement>('.a-scroll-notification');
    const videoButton = container.querySelector<HTMLElement>('.a-video-button');

    if (backButton && headerCenter && videoButton) {
        const backButtonContent = Array.from(backButton.querySelectorAll<HTMLElement>('.a-header-back-content'));
        const headerCenterContent = Array.from(headerCenter.querySelectorAll<HTMLElement>('.a-header-center-content'));
        const videoButtonContent = Array.from(videoButton.querySelectorAll<HTMLElement>('.a-video-button-content'));

        gsap.timeline({ defaults: { ease, duration: 0.67 } })
            .to(
                backButton,
                {
                    width: 0,
                    height: 0,
                },
                0.6,
            )
            .to(
                backButtonContent,
                {
                    y: '105%',
                    stagger: 0.05,
                },
                0,
            )
            .to(
                headerCenter,
                {
                    width: 0,
                    height: 0,
                    minWidth: 'unset',
                },
                0.6,
            )
            .to(
                headerCenterContent,
                {
                    y: '105%',
                    stagger: 0.05,
                },
                0,
            )
            .to(
                videoButton,
                {
                    width: 0,
                    height: 0,
                    minWidth: 'unset',
                },
                0.6,
            )
            .to(
                videoButtonContent,
                {
                    y: '105%',
                    stagger: 0.05,
                },
                0,
            )
            .add(() => {
                document.dispatchEvent(new Event('leave-map'));
            }, 0.2)
            .to(scrollNotification, { opacity: 0 }, 0.1)
            .to(
                mapSvg,
                {
                    opacity: 0,
                },
                0.3,
            )
            .to(
                mapCities,
                {
                    opacity: 0,
                },
                0,
            )
            .then(resolve);
    } else {
        resolve();
    }

    window.removeEventListener('resize', checkMapState);
};

export const mapLeaveToLanding = (container: HTMLElement, resolve: (value?: unknown) => void) => {
    const backButton = container.querySelector<HTMLElement>('.a-header-back');
    const headerCenter = container.querySelector<HTMLElement>('.a-header-center');
    const scrollNotification = container.querySelector<HTMLElement>('.a-scroll-notification');
    const videoButton = container.querySelector<HTMLElement>('.a-video-button');

    if (backButton && headerCenter && videoButton) {
        const backButtonContent = Array.from(backButton.querySelectorAll<HTMLElement>('.a-header-back-content'));
        const headerCenterContent = Array.from(headerCenter.querySelectorAll<HTMLElement>('.a-header-center-content'));
        const videoButtonContent = Array.from(videoButton.querySelectorAll<HTMLElement>('.a-video-button-content'));

        gsap.timeline({ defaults: { ease, duration: 0.67 } })
            .to(
                backButton,
                {
                    width: 0,
                    height: 0,
                },
                0.6,
            )
            .to(
                backButtonContent,
                {
                    y: '105%',
                    stagger: 0.05,
                },
                0,
            )
            .to(
                headerCenter,
                {
                    width: 0,
                    height: 0,
                    minWidth: 'unset',
                },
                0.6,
            )
            .to(
                headerCenterContent,
                {
                    y: '105%',
                    stagger: 0.05,
                },
                0,
            )
            .to(
                videoButton,
                {
                    width: 0,
                    height: 0,
                    minWidth: 'unset',
                },
                0.6,
            )
            .to(
                videoButtonContent,
                {
                    y: '105%',
                    stagger: 0.05,
                },
                0,
            )
            .to(scrollNotification, { opacity: 0 }, 0.1)
            .add(() => {
                document.dispatchEvent(new Event('leave-map'));
            }, 0.2)
            .to(
                mapCities,
                {
                    opacity: 0,
                },
                0,
            )
            .to(
                mapSvg,
                {
                    scale: isMobile ? undefined : 0.5,
                    x: isMobile ? 0 : '-25%',
                    y: isMobile ? 0 : '25%',
                    opacity: isMobile ? 0 : 1,
                    duration: 1.3,
                },
                0.5,
            )
            .then(resolve);
    } else {
        resolve();
    }
    window.removeEventListener('resize', checkMapState);
};
