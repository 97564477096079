const onMouseEnter = (event: Event) => {
    const eventTarget = event.target as HTMLElement;
    const video = eventTarget.querySelector('video');

    if (video) {
        video.play();
    }
};

const onMouseLeave = (event: Event) => {
    const eventTarget = event.target as HTMLElement;
    const video = eventTarget.querySelector('video');

    if (video) {
        video.pause();
    }
};

function init(container: HTMLElement | Document = document) {
    const productionItems = Array.from(container.querySelectorAll('.js-production-item'));

    productionItems.forEach((item) => {
        item.addEventListener('mouseenter', onMouseEnter);
        item.addEventListener('mouseleave', onMouseLeave);
    });
}

function destroy(container: HTMLElement | Document = document) {
    const productionItems = Array.from(container.querySelectorAll('.js-production-item'));

    productionItems.forEach((item) => {
        item.removeEventListener('mouseenter', onMouseEnter);
        item.removeEventListener('mouseleave', onMouseLeave);
    });
}

const _module = { init, destroy };

export default _module;
