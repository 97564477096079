import type { ProjectInfo } from '@/data/projects';
import { h } from 'preact';
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from 'react';

const ExperienceContext = createContext<{
    activeProjectSlug: string;
    projects: ProjectInfo[];
    setActiveProjectSlug: Dispatch<SetStateAction<string>>;
    isAnimating: boolean;
    setIsAnimating: Dispatch<SetStateAction<boolean>>;
    setProjects: Dispatch<SetStateAction<ProjectInfo[]>>;
}>({
    activeProjectSlug: '',
    projects: [],
    setActiveProjectSlug: () => {},
    isAnimating: false,
    setIsAnimating: () => {},
    setProjects: () => {},
});

export const ExperienceContextProvider = ({
    projects: initialProjects,
    children,
}: {
    projects: ProjectInfo[];
    children: ReactNode;
}) => {
    const [activeProjectSlug, setActiveProjectSlug] = useState(initialProjects[0].projectSlug);
    const [isAnimating, setIsAnimating] = useState(false);
    const [projects, setProjects] = useState(initialProjects);

    return (
        <ExperienceContext.Provider
            value={{
                activeProjectSlug,
                projects,
                setActiveProjectSlug,
                isAnimating,
                setIsAnimating,
                setProjects,
            }}
        >
            {children}
        </ExperienceContext.Provider>
    );
};

export const useExperienceContext = () => useContext(ExperienceContext);
