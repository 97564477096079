import { h } from 'preact';
import React, { useState, useEffect, useRef } from 'react';
import { AnimatePresence, motion, useWillChange } from 'framer-motion';
import ClipReveal from '../ClipReveal/ClipReveal';
import { tp } from '@/typograf';
import { CloseIcon } from '../svg/CloseIcon';
import { fadeInVariants } from '../ProductionTabs/production-tabs-animation-variants';
import { WhiteArrowIcon } from '../svg/WhiteArrowIcon';

const data = [
    {
        title: 'Строительная отрасль',
        description: tp(
            'Зола-уноса используется в качестве минеральной добавки, в качестве наполнителя для частичной замены вяжущего компонента или мелкого заполнителя',
        ),
        img: 'pos-2',
    },
    {
        title: 'Рекультивация',
        description: tp(
            'Золошлаковые материалы – готовое сырье для выполнения технического этапа рекультивации. Используются в качестве замены природного грунта при восстановлении нарушенных земель, отсыпке котлованов и выемок',
        ),
        img: 'pos-1',
    },
    {
        title: 'Дорожное строительство',
        description: tp(
            'Зола-уноса используется в качестве медленно твердеющего самостоятельного вяжущего для устройства оснований дорожных одежд из укрепленных грунтов. Золошлаковые материалы – для сооружения земляного полотна или устройства дополнительных слоев оснований дорожных одежд.',
        ),
        img: 'pos-4',
    },
];
const PossibilitiesPopup = () => {
    return (
        <div className="tps-container possibilities-container">
            <motion.div className="tps-project">
                <button
                    className="tps-close-button button button-close"
                    aria-label="Закрыть"
                    data-lit-popup-close="possibilities-popup"
                >
                    <div className="button__inner">
                        <CloseIcon />
                    </div>
                    <div className="button__inner button__inner-copy">
                        <CloseIcon />
                    </div>
                </button>

                <div className="possibilities-wrapper">
                    <ClipReveal>
                        <div className="h3 possibilities-title">Возможности применения</div>
                    </ClipReveal>
                    <div className="possibilities-content">
                        {data.map((item, i) => (
                            <div key={item.title} className="possibilities-card">
                                <div className="tps-image">
                                    <motion.picture
                                        initial={{ y: '-105%' }}
                                        animate={{
                                            y: 0,
                                            transition: {
                                                delay: 0.1,
                                                duration: 0.67,
                                                ease: [0.33, 0, 0.67, 1],
                                            },
                                        }}
                                        exit={{ y: '105%' }}
                                        key={i}
                                    >
                                        <source
                                            media="(max-width: 1279px)"
                                            srcSet={`/static/img/possibilities/${item.img}@resize-1000x.webp`}
                                            type="image/webp"
                                        />
                                        <source
                                            media="(max-width: 1920px)"
                                            srcSet={`/static/img/possibilities/${item.img}@resize-1900x.webp`}
                                            type="image/webp"
                                        />
                                        <source
                                            media="(min-width: 1921px)"
                                            srcSet={`/static/img/possibilities/${item.img}@resize-2500x.webp`}
                                            type="image/webp"
                                        />
                                        <source
                                            srcSet={`/static/img/possibilities/${item.img}@resize-2500x.webp`}
                                            type="image/webp"
                                        />
                                        <source
                                            media="(max-width: 1279px)"
                                            srcSet={`/static/img/possibilities/${item.img}@resize-1000x.jpg`}
                                        />
                                        <source
                                            media="(max-width: 1920px)"
                                            srcSet={`/static/img/possibilities/${item.img}@resize-1900x.jpg`}
                                        />
                                        <source
                                            media="(min-width: 1921px)"
                                            srcSet={`/static/img/possibilities/${item.img}@resize-2500x.jpg`}
                                        />
                                        <img
                                            alt={`Фотография - ${item.title}`}
                                            src={`/static/img/possibilities/${item.img}@resize-2500x.jpg`}
                                            width="2416"
                                            height="1812"
                                        />
                                    </motion.picture>
                                </div>
                                <div className="possibilities-card-bottom">
                                    <div className="possibilities-card-text">
                                        <div className="possibilities-title h4">{item.title}</div>
                                        <div className="possibilities-description">{item.description}</div>
                                    </div>
                                    {item.title === 'Дорожное строительство' && (
                                        <motion.button
                                            className="button-with-round-icon button-with-round-icon--primary"
                                            variants={fadeInVariants}
                                            animate={'active'}
                                            initial={'inactive'}
                                            data-lit-popup-open="possibilities-detail-popup"
                                        >
                                            <div className="button__icon">
                                                <WhiteArrowIcon />
                                            </div>
                                            <div className="button__text text-sm">
                                                Варианты конструкций
                                                <br />
                                                дорожных одежд
                                            </div>
                                            <div className="button__text button__text-copy text-sm">
                                                Варианты конструкций
                                                <br />
                                                дорожных одежд
                                            </div>
                                        </motion.button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default PossibilitiesPopup;
