export default function mapMemoryControlInit() {
    const map = document.querySelector('.js-map-wrapper');

    const onVisibilityChange = () => {
        if (map) {
            if (document.visibilityState === 'visible') {
                map.classList.remove('is-hidden');
            } else {
                map.classList.add('is-hidden');
            }
        }
    };

    document.addEventListener('visibilitychange', onVisibilityChange);
}
