import type { IView } from '@barba/core';
import productionItems from '../inits/production-items';
import productionTabs from '../inits/production-tabs';
import videoPopup from '../inits/video-popup';
import experiencePopup from '../inits/experience-popup';
import safetyPopup from '../inits/safety-popup';
import possibilitiesPopup from '../inits/possibilities-popup';
import possibilitiesDetailPopup from '../inits/possibilities-detail-popup';
import buttonsRipple from '../inits/buttons-ripple';
import { IdleQueue } from 'idlize/IdleQueue.mjs';

const queue = new IdleQueue();

export default {
    namespace: 'landing-page',

    beforeEnter({ next }) {
        queue.pushTask(() => {
            productionItems.init(next.container);
        });
        queue.pushTask(() => {
            buttonsRipple.init(next.container);
        });
        queue.pushTask(() => {
            productionTabs.init(next.container);
        });
        queue.pushTask(() => {
            experiencePopup.init(next.container);
        });
        queue.pushTask(() => {
            safetyPopup.init(next.container);
        });
        queue.pushTask(() => {
            videoPopup.init(next.container);
        });
        queue.pushTask(() => {
            possibilitiesPopup.init(next.container);
        });
        queue.pushTask(() => {
            possibilitiesDetailPopup.init(next.container);
        });
    },

    beforeLeave() {
        queue.clearPendingTasks();
    },

    afterLeave({ current }) {
        productionItems.destroy(current.container);
        safetyPopup.destroy(current.container);
        possibilitiesPopup.destroy(current.container);
        possibilitiesDetailPopup.destroy(current.container);
        experiencePopup.destroy(current.container);
        buttonsRipple.destroy(current.container);
    },
} as IView;
