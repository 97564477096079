type NormativeDocument = {
    slug: string;
    scope: {
        title: string;
        description: string;
    }[];
}[];

export const documents: NormativeDocument = [
    {
        slug: 'zola',
        scope: [
            {
                title: 'ГОСТ 25818-2017 «Золы-уноса тепловых электростанций для бетонов»',
                description:
                    'Определяет характеристики золы-уноса, применяемой в качестве компонента для изготовления тяжелых, легких, ячеистых бетонов и строительных растворов, сухих строительных смесей, минеральных вяжущих для приготовления смесей и укрепленных грунтов в дорожном строительстве',
            },
            {
                title: 'ГОСТ 31108-2020 «Цементы общестроительные. Технические условия»',
                description: 'Определяет золу-уноса как минеральную добавку при изготовлении цемента',
            },
            {
                title: 'ГОСТ Р 56196-2014 «Добавки активные минеральные для цементов. Общие технические условия»',
                description: 'Определяет золу-уноса как активную техногенную минеральную добавку',
            },
            {
                title: 'ГОСТ Р 55224-2020 «Цементы для транспортного строительства. Технические условия»',
                description:
                    'Определяет золу-уноса как минеральную добавку при изготовлении цемента для транспортного строительства (по ГОСТ 31108)',
            },
            {
                title: 'ОДМ 218.2.031-2013 «Методические рекомендации по применению золы уноса и золошлаковых смесей от сжигания угля на тепловых электростанциях в дорожном строительстве»',
                description: 'Рекомендации по применению золы-уноса и золошлаков в дорожном строительстве',
            },
            {
                title: 'ГОСТ Р 59300-2021 «Дороги автомобильные общего пользования. Смеси бетонные для устройства слоев оснований и покрытий»',
                description:
                    'Определяет золу-уноса как минеральную добавку при изготовлении бетонных смесей для устройства слоев оснований и покрытий',
            },
            {
                title: 'ГОСТ 32761-2014 «Дороги автомобильные общего пользования. Порошок минеральный»',
                description: 'Предусматривает применение золы- уноса в качестве минерального порошка МП-3',
            },
            {
                title: 'ГОСТ 33174-2014 «Дороги автомобильные общего пользования. Цемент»',
                description: 'Предусматривает применение золы- уноса в качестве добавки при производстве цемента',
            },
            {
                title: 'ГОСТ Р 59300-2021 «Дороги автомобильные общего пользования. Смеси бетонные для устройства слоев оснований и покрытий»',
                description: 'Предусматривает применение золы- уноса в качестве минеральной добавки в бетоне',
            },
            {
                title: 'ГОСТ Р 70196-2022 «Дороги автомобильные общего пользования. Комплексные минеральные вяжущие для стабилизации и укрепления грунтов»',
                description:
                    'Зола-уноса применяется в качестве компонента состава, комбинированного минерального вяжущего',
            },
            {
                title: 'ГОСТ Р 70455-2022 «Дороги автомобильные общего пользования. Смеси щебеночно гравийно песчаные, обработанные неорганическими вяжущими»',
                description: 'Предусматривает применение золы-уноса и золошлаков в качестве гранулирующей добавки',
            },
            {
                title: 'ГОСТ 23558-94 «Смеси щебеночно гравийно песчаные и грунты, обработанные неорганическими вяжущими материалами, для дорожного и аэродромного строительства»',
                description: 'Предусматривает применение золы-уноса в качестве вяжущего II вида',
            },
        ],
    },
    //Золошлаковые материалы
    {
        slug: 'zshm',
        scope: [
            {
                title: 'ГОСТ Р 70452-2022 «Дороги автомобильные общего пользования. Грунты стабилизированные и укрепленные неорганическими вяжущими»',
                description:
                    'Предусматривает применение золошлаковых отходов в качестве укрепляемых техногенных грунтов',
            },
            {
                title: 'ГОСТ 25100-2020 «Грунты. Классификация».',
                description: 'Определяет золошлаки, как антропогенный грунт',
            },
            {
                title: 'ГОСТ 25592-2019 «Смеси золошлаковые тепловых электростанций для бетонов. Технические условия»',
                description:
                    'Определяет характеристики золошлаковых смесей, применяемых в качестве компонентов для изготовления бетонов, строительных растворов, сухих строительных смесей, минеральных вяжущих, смесей щебеночно-гравийно-песчаных для покрытий и оснований автомобильных дорог и аэродромов, а также для получения минерального порошка',
            },
        ],
    },
    {
        slug: 'recycle',
        scope: [
            {
                title: 'ГОСТ 32826-2014 «Дороги автомобильные общего пользования. Щебень и песок шлаковые»',
                description: 'Классификация песка и щебня выделенных из золошлаков',
            },
        ],
    },
];
