import { h } from 'preact';
import React from 'react';

export const PlusSvg = () => {
    return (
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.80615 4.86182V7.45801H3.1875V4.86182H0.572266V3.22412H3.1875V0.634277H4.80615V3.22412H7.42773V4.86182H4.80615Z"
                fill="white"
            />
        </svg>
    );
};
