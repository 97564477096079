import { ITransitionPage } from '@barba/core';
import { landingLeaveToMap } from '@/animations/landing-page-animations';
import { mapEnterFromLanding } from '@/animations/map-page-animations';

export default {
    name: 'landing-to-map-transition',

    from: {
        namespace: 'landing-page',
    },

    to: {
        namespace: 'map-page',
    },

    enter() {
        window.scrollTo({ top: 0, behavior: 'auto' });
    },

    beforeLeave({ current }) {
        return new Promise((resolve) => {
            landingLeaveToMap(current.container, resolve);
        });
    },

    afterEnter({ next }) {
        mapEnterFromLanding(next.container);
    },
} as ITransitionPage;
