import { LitElement, css, html, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import Cookies from 'js-cookie';

import _styles from './CookiesAgreement.ce.scss';

export interface CookiesAgreement {
    _isVisible: boolean;
    _timer: NodeJS.Timeout;
}

/**
 * @attr {Number} reveal-timeout - Через сколько секунд сообщение покажется пользователю
 *
 * @slot - Контент в формате HTML
 */
export class CookiesAgreement extends LitElement {
    constructor() {
        super();
        this._acceptCookiesUsage = this._acceptCookiesUsage.bind(this);
        this._isVisible = false;
    }

    static get properties() {
        return {
            _isVisible: {
                type: Boolean,
                attribute: false,
            },
        };
    }

    static get styles() {
        return css`
            ${unsafeCSS(_styles)}
        `;
    }

    connectedCallback() {
        super.connectedCallback();
        this._isVisible = true;
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        clearTimeout(this._timer);
    }

    close() {
        this._isVisible = false;
    }

    protected _acceptCookiesUsage() {
        Cookies.set('COOKIES_USAGE_ACCEPTED', 'true', {
            expires: 365, // days
        });
        this.close();
    }

    render() {
        return html`
            <div class="${classMap({ banner: true, 'banner--visible': this._isVisible })}">
                <svg width="410" height="72" viewBox="0 0 410 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M36 0C16.1178 0 0 16.1178 0 36C0 55.8823 16.1177 72 36 72H319C327.901 72 336.048 68.7695 342.331 63.4171C344.686 61.4114 348.314 61.4114 350.669 63.4171C356.952 68.7695 365.099 72 374 72C393.882 72 410 55.8823 410 36C410 16.1178 393.882 0 374 0C365.099 0 356.952 3.23046 350.669 8.58291C348.314 10.5886 344.686 10.5886 342.331 8.58291C336.048 3.23046 327.901 0 319 0H36Z"
                        fill="white"
                    />
                    <rect
                        class="banner__close"
                        x="350"
                        y="12"
                        width="48"
                        height="48"
                        rx="24"
                        fill="url(#paint0_linear_901_378)"
                        @click="${this._acceptCookiesUsage}"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M378.799 27.9985C379.682 27.1148 381.115 27.1148 381.999 27.9985C382.883 28.8823 382.883 30.3151 381.999 31.1988C381.84 31.3574 381.664 31.4875 381.477 31.5891C380.473 32.1332 379.16 32.2529 378.352 33.0602L377.535 33.878C376.363 35.0496 376.363 36.9491 377.535 38.1207L378.352 38.9385C379.16 39.7458 380.473 39.8655 381.477 40.4096C381.664 40.5113 381.84 40.6413 381.999 40.7999C382.883 41.6836 382.883 43.1165 381.999 44.0002C381.115 44.8839 379.682 44.8839 378.799 44.0002C378.64 43.8416 378.51 43.6654 378.408 43.4779C377.864 42.4735 377.745 41.1591 376.937 40.3514L376.12 39.5349C374.949 38.3633 373.049 38.3633 371.878 39.5349L371.061 40.3514C370.253 41.1591 370.134 42.4735 369.59 43.4779C369.488 43.6654 369.358 43.8416 369.199 44.0002C368.316 44.8839 366.883 44.8839 365.999 44.0002C365.115 43.1165 365.115 41.6836 365.999 40.7999C366.158 40.6413 366.334 40.5113 366.521 40.4096C367.525 39.8655 368.838 39.7458 369.646 38.9385L370.463 38.1207C371.635 36.9491 371.635 35.0496 370.463 33.878L369.646 33.0602C368.838 32.2529 367.525 32.1332 366.521 31.5891C366.334 31.4875 366.158 31.3574 365.999 31.1988C365.115 30.3151 365.115 28.8823 365.999 27.9985C366.883 27.1148 368.316 27.1148 369.199 27.9985C369.358 28.1571 369.488 28.3333 369.59 28.5208C370.134 29.5252 370.253 30.8396 371.061 31.6474L371.878 32.4638C373.049 33.6354 374.949 33.6354 376.12 32.4638L376.937 31.6474C377.745 30.8396 377.864 29.5252 378.408 28.5208C378.51 28.3333 378.64 28.1571 378.799 27.9985Z"
                        fill="white"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_901_378"
                            x1="392.772"
                            y1="12.1278"
                            x2="356.304"
                            y2="14.4997"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="#E34643" />
                            <stop offset="1" stop-color="#EA673E" />
                        </linearGradient>
                    </defs>
                </svg>
                <div class="banner__text">
                    <slot></slot>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'app-cookies-agreement': CookiesAgreement;
    }
}

if (Cookies.get('COOKIES_USAGE_ACCEPTED') !== 'true') {
    customElements.define('app-cookies-agreement', CookiesAgreement);
}
