import { h } from 'preact';
import React from 'react';
import { useCitiesAndTpsContext } from '../CitiesAndTpsContext';
import { motion } from 'framer-motion';

const EXIT_DURATION = 0.5;

const ClosedView = () => {
    const { activeView, setView } = useCitiesAndTpsContext();

    return (
        <button
            className="city-and-tps-closed"
            onClick={() => {
                setView('choose-city');
            }}
        >
            <motion.div
                initial={{
                    opacity: 0,
                }}
                animate={{
                    opacity: 1,
                    transition: {
                        delay: 0.67,
                        duration: 0.67,
                    },
                }}
                exit={{
                    opacity: 0,
                    transition: {
                        duration: EXIT_DURATION,
                    },
                }}
                className="animated-circles"
            >
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
            </motion.div>
        </button>
    );
};

export default ClosedView;
