import { ITransitionPage } from '@barba/core';
import { indexEnter } from '@/animations/index-page-animations';

export default {
    name: 'index-once-transition',

    to: {
        namespace: 'index-page',
    },

    once({ next }) {
        indexEnter(next.container);
    },
} as ITransitionPage;
