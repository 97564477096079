import type { IView } from '@barba/core';
import citiesAndTpsElement from '../inits/cities-and-tps-element';
import videoPopup from '../inits/video-popup';

export default {
    namespace: 'map-page',

    afterEnter({ next }) {
        citiesAndTpsElement.init(next.container);
        videoPopup.init(next.container);
    },

    afterLeave({ current }) {
        citiesAndTpsElement.destroy(current.container);
    },
} as IView;
