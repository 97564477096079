import { Fragment, h } from 'preact';
import React, { useRef, useState, useEffect } from 'react';
import { useCitiesAndTpsContext } from './CitiesAndTpsContext';
import { motion, useIsomorphicLayoutEffect, AnimatePresence } from 'framer-motion';
import { getCSSCustomProp } from '@/utils/css';

import { CitiesAndTpsContextProvider } from './CitiesAndTpsContext';
import ChooseCityView from './views/ChooseCityView';
import ClosedView from './views/ClosedView';
import ChooseTpsView from './views/ChooseTpsView';
import TpsView from './views/TpsView';
import ClipReveal from '../ClipReveal/ClipReveal';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import { timeout } from '@/utils/timeout';
import debounce from 'lodash.debounce';
import classNames from 'classnames';

const transition = {
    duration: 0.67,
    ease: [0.33, 0, 0.67, 1],
};

const TitleElement = ({ text, delay = 0 }: { text: string; delay?: number }) => {
    const { width } = useCitiesAndTpsContext();

    return (
        <motion.div
            className="cities-and-tps-title"
            initial={{
                transform: `translateX(calc(100% - ${width}px))`,
            }}
            animate={{
                transform: `translateX(calc(100% - ${width}px))`,
            }}
            transition={transition}
            key={text}
        >
            <ClipReveal slug="title" delay={delay}>
                <div className="text-md">{text}</div>
            </ClipReveal>
        </motion.div>
    );
};

const CitiesAndTpsElementWrapper = () => {
    const {
        activeView,
        width,
        height,
        setWidth,
        setHeight,
        setView,
        activeCitySlug,
        activeCityRegion,
        setActiveCitySlug,
        setTpsRevealDelay,
        setTpsViewCloseTarget,
    } = useCitiesAndTpsContext();
    const contentRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [isInitialAnimationComplete, setIsInitialAnimationComplete] = useState(false);
    const offset = getCSSCustomProp(document.documentElement, '--offset-x', 'number') as number;
    const radius = getCSSCustomProp(document.documentElement, '--app-border-radius', 'number') as number;
    const [isExit, setIsExit] = useState(false);
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const isMobile = window.matchMedia('(max-width: 767px)').matches;
    const [isAnyViewOpened, setIsAnyViewOpened] = useState(false);
    const [isSwapBg, setIsSwapBg] = useState(false);
    const [isSwapHeaderBg, setIsSwapHeaderBg] = useState(false);

    const [numberKey, setNumberKey] = useState(0);

    useEffect(() => {
        const debouncedOnResize = debounce(() => {
            setViewportWidth(window.innerWidth);
        }, 50);

        window.addEventListener('resize', debouncedOnResize);

        return () => {
            window.removeEventListener('resize', debouncedOnResize);
        };
    }, []);

    useOnClickOutside(
        wrapperRef,
        () => {
            setView('closed');
        },
        'mousedown',
        '.js-ignore-outside-click',
    );

    useEffect(() => {
        const onKeydown = (event: KeyboardEvent) => {
            if (event.code === 'Escape') {
                setView('closed');
                setTpsViewCloseTarget('choose-city');
            }
        };

        document.addEventListener('keydown', onKeydown);

        return () => document.removeEventListener('keydown', onKeydown);
    }, [setView, setTpsViewCloseTarget]);

    useEffect(() => {
        const onLeaveMap = async () => {
            setIsExit(true);
            await timeout(200);
            setWidth(0);
            setHeight(0);
        };

        document.addEventListener('leave-map', onLeaveMap);

        return () => {
            document.removeEventListener('leave-map', onLeaveMap);
        };
    }, [setView, setWidth, setHeight]);

    useEffect(() => {
        const cityTriggers = Array.from(document.querySelectorAll<HTMLElement>('.js-city-trigger'));

        const onClick = (event: MouseEvent) => {
            const eventTarget = event.target as HTMLElement;
            const trigger = eventTarget.closest<HTMLElement>('.js-city-trigger');

            if (trigger) {
                const currentCity = trigger.dataset.city;

                if (currentCity) {
                    setNumberKey((prev) => prev + 1);
                    setActiveCitySlug(currentCity);
                    setTpsViewCloseTarget('closed');
                    setView('choose-tps');
                    setTpsRevealDelay(0.67);
                }
            }
        };

        cityTriggers.forEach((trigger) => {
            trigger.addEventListener('click', onClick);
        });

        return () => {
            cityTriggers.forEach((trigger) => {
                trigger.removeEventListener('click', onClick);
            });
        };
    }, [setActiveCitySlug, setView, setTpsViewCloseTarget, setTpsRevealDelay]);

    useIsomorphicLayoutEffect(() => {
        if (contentRef.current) {
            const rect = contentRef.current.getBoundingClientRect();
            setWidth(Math.round(rect.width));
            setHeight(Math.round(rect.height));
        }
    }, [activeView]);

    useEffect(() => {
        if (contentRef.current) {
            const rect = contentRef.current.getBoundingClientRect();
            setWidth(Math.round(rect.width));
            setHeight(Math.round(rect.height));
        }
    }, [setWidth, setHeight, viewportWidth, numberKey]);

    return (
        <Fragment>
            <div
                className={classNames('cities-and-tps-overlay', {
                    'cities-and-tps-overlay--visible': activeView === 'tps',
                })}
            />
            <div
                className={classNames('cities-and-tps-element', {
                    'cities-and-tps-element--opened': isAnyViewOpened,
                    'swap-bg': isSwapBg,
                    'swap-header-bg': isSwapHeaderBg,
                })}
                ref={wrapperRef}
            >
                <motion.div
                    className="cities-and-tps-element__bg"
                    animate={{
                        width: activeView === 'tps' ? width : width + radius * 2,
                        height,
                        boxShadow:
                            activeView === 'choose-city' || activeView === 'choose-tps'
                                ? '0 12px 32px -8px rgba(33, 32, 31, 0.4)'
                                : 'none',
                    }}
                    transition={transition}
                    onAnimationComplete={(instance: { width: number; height: number }) => {
                        if (activeView === 'tps' && instance.width === width) {
                            setIsSwapBg(true);
                        }
                        if (activeView === 'closed' && instance.width === width + radius * 2) {
                            setIsInitialAnimationComplete(true);
                        }
                        if (
                            (activeView === 'choose-city' || activeView === 'choose-tps') &&
                            instance.width === width + radius * 2
                        ) {
                            setIsSwapHeaderBg(true);
                        }
                        setIsAnyViewOpened(true);
                    }}
                    onAnimationStart={() => {
                        if (activeView !== 'tps') {
                            setIsSwapBg(false);
                        }
                        if (activeView !== 'choose-city' && activeView !== 'choose-tps') {
                            setIsSwapHeaderBg(false);
                        }
                        setIsAnyViewOpened(false);
                    }}
                />
                <motion.div
                    className="cities-and-tps-element-content"
                    style={{ right: activeView === 'tps' ? 0 : offset }}
                >
                    <AnimatePresence mode="sync">
                        <AnimatePresence mode="wait">
                            {isInitialAnimationComplete && !isExit && (
                                <Fragment>
                                    {activeView === 'closed' || activeView === 'choose-city' ? (
                                        <TitleElement
                                            text={isMobile && activeView === 'closed' ? '' : 'Выберите город'}
                                            key={'initial'}
                                            delay={0.8}
                                        />
                                    ) : activeView === 'choose-tps' ? (
                                        <TitleElement
                                            text={activeCityRegion || 'Выберите город'}
                                            key={activeCitySlug}
                                            delay={0.8}
                                        />
                                    ) : null}
                                </Fragment>
                            )}
                        </AnimatePresence>
                        {!isExit && (
                            <motion.div
                                className="cities-and-tps-element-content__content"
                                style={{ overflow: activeView === 'tps' ? 'visible' : '' }}
                                ref={contentRef}
                            >
                                {activeView === 'closed' && <ClosedView key={activeView} />}
                                {activeView === 'choose-city' && <ChooseCityView key={activeView} />}
                                {activeView === 'choose-tps' && <ChooseTpsView key={activeView + numberKey} />}
                                {activeView === 'tps' && <TpsView key={activeView} />}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </motion.div>
            </div>
        </Fragment>
    );
};

const CitiesAndTpsElement = () => {
    return (
        <CitiesAndTpsContextProvider>
            <CitiesAndTpsElementWrapper />
        </CitiesAndTpsContextProvider>
    );
};

export default CitiesAndTpsElement;
