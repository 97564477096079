import { h } from 'preact';
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import type { CitySlug } from '@/data/cities';
import { citiesData } from '@/data/cities';

type Views = 'closed' | 'choose-city' | 'choose-tps' | 'tps';

const CitiesAndTpsContext = createContext<{
    width: number;
    height: number;
    setWidth: Dispatch<SetStateAction<number>>;
    setHeight: Dispatch<SetStateAction<number>>;
    activeView: Views;
    setView: Dispatch<SetStateAction<Views>>;
    activeCitySlug?: CitySlug;
    setActiveCitySlug: Dispatch<SetStateAction<string>>;
    activeCityRegion?: string;
    activeTpsSlug?: string;
    setActiveTpsSlug: Dispatch<SetStateAction<string>>;
    tpsRevealDelay: number;
    setTpsRevealDelay: Dispatch<SetStateAction<number>>;
    tpsViewCloseTarget: 'closed' | 'choose-city';
    setTpsViewCloseTarget: Dispatch<SetStateAction<'closed' | 'choose-city'>>;
}>({
    width: 0,
    height: 0,
    setWidth: () => {},
    setHeight: () => {},
    activeView: 'closed',
    setView: () => {},
    activeCitySlug: undefined,
    setActiveCitySlug: () => {},
    activeCityRegion: '',
    activeTpsSlug: '',
    setActiveTpsSlug: () => {},
    tpsRevealDelay: 0,
    setTpsRevealDelay: () => {},
    tpsViewCloseTarget: 'choose-city',
    setTpsViewCloseTarget: () => {},
});

export const CitiesAndTpsContextProvider = ({ children }: { children: ReactNode }) => {
    const [width, setWidth] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);
    const [activeView, setView] = useState<Views>('closed');
    const [activeCitySlug, setActiveCitySlug] = useState<CitySlug>();
    const [activeCityRegion, setActiveCityRegion] = useState<string>();
    const [activeTpsSlug, setActiveTpsSlug] = useState<string>();
    const [tpsRevealDelay, setTpsRevealDelay] = useState<number>(0);
    const [tpsViewCloseTarget, setTpsViewCloseTarget] = useState<'closed' | 'choose-city'>('choose-city');

    useEffect(() => {
        const activeCity = citiesData.find((item) => item.slug === activeCitySlug);
        if (activeCity) {
            setActiveCityRegion(activeCity.region);
        }
    }, [activeCitySlug]);

    return (
        <CitiesAndTpsContext.Provider
            value={{
                width,
                height,
                setWidth,
                setHeight,
                activeView,
                setView,
                activeCitySlug,
                setActiveCitySlug,
                activeCityRegion,
                activeTpsSlug,
                setActiveTpsSlug,
                tpsRevealDelay,
                setTpsRevealDelay,
                tpsViewCloseTarget,
                setTpsViewCloseTarget,
            }}
        >
            {children}
        </CitiesAndTpsContext.Provider>
    );
};

export const useCitiesAndTpsContext = () => useContext(CitiesAndTpsContext);
