import gsap from 'gsap';
import barba from '@barba/core';
import { ease } from '../animations/easings';

export const preloaderAnimationDuration = 1; // sec

function createPreloader() {
    const preloader = document.querySelector<HTMLElement>('.js-preloader');
    let loaded = false;

    const state = {
        completed: false,
        currentPercent: 0,
    };

    document.body.classList.add('no-scroll');

    function leave(): Promise<void> {
        return new Promise((resolve) => {
            document.dispatchEvent(new Event('preloader-leave'));

            barba.hooks.afterEnter(() => {
                if (!loaded) {
                    loaded = true;
                }
            });

            const tl = gsap.timeline();
            tl.to(preloader, {
                duration: 0.5,
                opacity: 0,
                ease,
                onComplete: () => {
                    state.completed = true;
                },
            }).add(() => {
                resolve();
                document.body.classList.remove('no-scroll');
                preloader?.remove();
            });
        });
    }

    function loadAsset(asset: HTMLImageElement | HTMLVideoElement): Promise<void> {
        return new Promise((resolve) => {
            if (asset instanceof HTMLImageElement) {
                if (asset.complete) {
                    resolve();
                } else {
                    asset.onload = () => {
                        resolve();
                    };
                    asset.onerror = () => {
                        resolve();
                    };
                }
            }

            if (asset instanceof HTMLVideoElement) {
                if (asset.readyState >= 1) {
                    resolve();
                } else {
                    asset.addEventListener('loadedmetadata', () => resolve(), { once: true });
                    asset.onerror = () => {
                        resolve();
                    };
                }
            }
        });
    }

    async function loadAssetsFromElement(element: Element | Document = document) {
        const images = Array.from(element.querySelectorAll<HTMLImageElement>('img:not(.lazy):not([loading="lazy"])'));
        const videos = Array.from(
            element.querySelectorAll<HTMLImageElement>('video:not(.lazy):not([loading="lazy"]):not(.js-intro-video)'),
        );
        const assets: Array<HTMLImageElement | HTMLVideoElement> = [...images, ...videos];
        if (assets.length > 0) {
            await Promise.all<any>(assets.map((asset) => loadAsset(asset)));
        }
    }
    async function loadAssets() {
        await loadAssetsFromElement(document.body);
    }

    return { leave, loadAssets, state } as const;
}

export const preloader = createPreloader();
