import { LitPopupElement } from '@/custom-elements/LitPopupElement/LitPopupElement';
import { h } from 'preact';
import React, { useEffect, useRef, useState, useCallback, MouseEvent } from 'react';
import { formatTimeInSeconds } from '@/utils/strings/index';
import classNames from 'classnames';
import { viewport } from '@/viewport';
import { CloseIcon } from '../svg/CloseIcon';
import { SibgencoSvg } from '../svg/SibgencoSvg';
import { ReplaySvg } from '../svg/ReplaySvg';
import { PlaySvg } from '../svg/PlaySvg';
import { PauseSvg } from '../svg/PauseSvg';
import { useIsomorphicLayoutEffect } from 'framer-motion';

type Props = {
    src: string;
    name?: string;
};

const TIME_TO_IDLE = 2;

const VideoPlayer = ({ src, name = 'video-player' }: Props) => {
    const videoEl = useRef<HTMLVideoElement>(null);
    const seekEl = useRef<HTMLButtonElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const volumeEl = useRef<HTMLButtonElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [totalDuration, setTotalDuration] = useState(0);
    const [isPointerDown, setIsPointerDown] = useState(false);
    const [isPlayingBeforePointerDown, setIsPlayingBeforePointerDown] = useState(false);

    const [seekWidth, setSeekWidth] = useState(0);
    const [seekOffset, setSeekOffset] = useState(0);
    const [volume, setVolume] = useState(0.2);

    const [changedTime, setChangedTime] = useState<number | null>(null);

    const timeoutRef = useRef<NodeJS.Timeout>();

    const setTimer = useCallback(() => {
        timeoutRef.current = setTimeout(() => {
            if (wrapperRef.current) {
                wrapperRef.current.classList.add('navigation-hidden');
            }
        }, 1000 * TIME_TO_IDLE);
    }, []);

    const resetTimer = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        if (wrapperRef.current) {
            wrapperRef.current.classList.remove('navigation-hidden');
        }
        setTimer();
    }, [setTimer]);

    useEffect(() => {
        document.addEventListener('mousemove', resetTimer);
        document.addEventListener('click', resetTimer);
        document.addEventListener('touchstart', resetTimer);
        document.addEventListener('keypress', resetTimer);
        setTimer();

        return () => {
            document.removeEventListener('mousemove', resetTimer);
            document.removeEventListener('click', resetTimer);
            document.removeEventListener('touchstart', resetTimer);
            document.removeEventListener('keypress', resetTimer);
        };
    }, [resetTimer, setTimer]);

    useEffect(() => {
        const videoElement = videoEl.current;

        const onPopupOpen = () => {
            if (videoElement) {
                videoElement.play();
                setIsPlaying(true);
            }
        };

        const onPopupClose = () => {
            if (videoElement) {
                videoElement.pause();
                videoElement.currentTime = 0;
                setIsPlaying(false);
            }
        };
        if (videoElement) {
            const videoPopup = videoElement.closest<LitPopupElement>('app-lit-popup');

            if (videoPopup) {
                videoPopup.addEventListener('open', onPopupOpen);
                videoPopup.addEventListener('close-complete', onPopupClose);
            }
        }

        return () => {
            if (videoElement) {
                const videoPopup = videoElement.closest<LitPopupElement>('app-lit-popup');
                if (videoPopup) {
                    videoPopup.removeEventListener('open', onPopupOpen);
                    videoPopup.removeEventListener('close-complete', onPopupClose);
                }
            }
        };
    }, []);

    useIsomorphicLayoutEffect(() => {
        if (seekEl.current) {
            const rect = seekEl.current.getBoundingClientRect();
            setSeekOffset(rect.x > viewport.width ? rect.x - viewport.width : rect.x);
            setSeekWidth(rect.width);
        }
    }, []);

    const onSeek = useCallback(
        (position: number) => {
            let clickPosition = position - seekOffset;
            if (clickPosition > seekWidth) {
                clickPosition = seekWidth;
            }
            if (clickPosition < 0) {
                clickPosition = 0;
            }
            setChangedTime((clickPosition / seekWidth) * totalDuration);
        },
        [totalDuration, seekOffset, seekWidth],
    );

    useEffect(() => {
        if (videoEl.current) {
            isPlaying ? videoEl.current.play() : videoEl.current.pause();
        }
    }, [isPlaying]);

    useEffect(() => {
        if (videoEl.current) {
            videoEl.current.volume = volume;
        }
    }, [volume]);

    useEffect(() => {
        const video = videoEl.current;

        const onTimeUpdate = (event: Event) => {
            if (event.target instanceof HTMLVideoElement) {
                setCurrentTime(event.target.currentTime);
                if (event.target.currentTime === totalDuration) {
                    setIsPlaying(false);
                }
            }
        };

        if (video) {
            video.addEventListener('timeupdate', onTimeUpdate);
        }

        return () => {
            if (video) {
                video.removeEventListener('timeupdate', onTimeUpdate);
            }
        };
    }, [setCurrentTime, totalDuration]);

    useEffect(() => {
        const { current } = videoEl;

        const onDurationChange = () => {
            if (current) {
                if (!isNaN(current.duration)) {
                    setTotalDuration(current.duration);
                }
            }
        };

        onDurationChange();

        if (current) {
            current.addEventListener('durationchange', onDurationChange);
        }

        return () => {
            if (current) {
                current.removeEventListener('durationchange', onDurationChange);
                if (!isNaN(current.duration)) {
                    setTotalDuration(current.duration);
                }
            }
        };
    }, [setTotalDuration]);

    useEffect(() => {
        const video = videoEl.current;

        if (video && changedTime !== null) {
            video.currentTime = changedTime;
            setChangedTime(null);
            setIsPlaying(isPlayingBeforePointerDown);
        }
    }, [changedTime, isPlayingBeforePointerDown]);

    useEffect(() => {
        if (isPlaying) {
            setIsPlaying(!isPointerDown);
        }
    }, [isPointerDown, isPlaying, setIsPlaying]);

    return (
        <div
            className="video-player-wrapper"
            onPointerUp={(event) => {
                if (isPointerDown) {
                    setIsPointerDown(false);
                    onSeek(event.clientX);
                }
            }}
            ref={wrapperRef}
        >
            <button className="video-player-close button button-close" data-lit-popup-close={name}>
                <div className="button__inner">
                    <CloseIcon />
                </div>
                <div className="button__inner button__inner-copy">
                    <CloseIcon />
                </div>
            </button>
            <div className="video-player-sgk-logo">
                <SibgencoSvg />
            </div>
            <video
                src={src}
                ref={videoEl}
                muted={isMuted}
                playsInline
                onClick={() => {
                    setIsPlaying((prevState) => !prevState);
                }}
            ></video>
            <div className="video-player-interface">
                <div className="video-player-buttons">
                    <button
                        className="button video-player-round-button"
                        onClick={() => {
                            setIsPlaying((prevState) => !prevState);
                        }}
                    >
                        {currentTime === totalDuration ? <ReplaySvg /> : isPlaying ? <PauseSvg /> : <PlaySvg />}
                    </button>

                    <button
                        className="button video-player-button text-md"
                        onClick={() => {
                            setIsMuted((prevState) => !prevState);
                        }}
                    >
                        {isMuted ? 'Вкл звук' : 'Выкл звук'}
                    </button>

                    <div className="video-player-volume">
                        <div className="text-md">Громкость</div>

                        <button
                            className="video-player-seek"
                            ref={volumeEl}
                            onClick={(event: MouseEvent) => {
                                if (event.target && !event.target.classList.contains('js-volume-circle')) {
                                    setVolume((event.layerX / event.target.offsetWidth) * 1);
                                }
                            }}
                        >
                            <div
                                className="video-player-seek__current"
                                style={{ transform: `scaleX(${volume.toFixed(2)})` }}
                            ></div>
                        </button>
                    </div>
                </div>

                <div className="video-player-indication">
                    <div className="video-player-duration">
                        <div className="video-player-duration__current text-lg">
                            {formatTimeInSeconds(Math.floor(currentTime))}
                        </div>
                        <div className="video-player-duration__total text-lg">
                            {formatTimeInSeconds(Math.floor(totalDuration))}
                        </div>
                    </div>
                    <button
                        className="video-player-seek"
                        ref={seekEl}
                        onClick={(event: MouseEvent) => {
                            if (event.target && !event.target.classList.contains('js-video-player-circle')) {
                                setChangedTime((event.layerX / event.target.offsetWidth) * totalDuration);
                            }
                        }}
                    >
                        <div
                            className="video-player-seek__current"
                            style={{ transform: `scaleX(${(currentTime / totalDuration).toFixed(2)})` }}
                        ></div>
                        <button
                            className={classNames('video-player-seek__circle js-video-player-circle', {
                                hold: isPointerDown,
                            })}
                            onPointerDown={() => {
                                setIsPlayingBeforePointerDown(isPlaying);
                                setIsPointerDown(true);
                            }}
                            style={{
                                '--seek-width': `${seekWidth}px`,
                                transform: `translateX(${
                                    seekWidth * (currentTime / totalDuration)
                                }px) translate3d(-50%, 0, 0)`,
                            }}
                        ></button>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VideoPlayer;
