import { ease } from '@/animations/easings';
import gsap from 'gsap';
import debounce from 'lodash.debounce';

const mapSvg = document.querySelector('.js-map-svg');
const mapCities = document.querySelector('.js-cities');
const isMobile = window.matchMedia('(max-width: 1279px)').matches;
const isPortrait = window.matchMedia('(orientation: portrait)').matches;

const checkMapState = debounce(() => {
    const isPortrait = window.matchMedia('(orientation: portrait)').matches;
    gsap.to(mapSvg, {
        scale: isPortrait ? 1 : 0.5,
        x: isPortrait ? 0 : '-25%',
        y: isPortrait ? 0 : '25%',
        opacity: 1,
        duration: 0.67,
    });
}, 50);

export const landingEnter = (container: HTMLElement) => {
    const clipElements = Array.from(container.querySelectorAll<HTMLElement>('.a-clip-element'));
    const decorElements = Array.from(container.querySelectorAll<HTMLElement>('.a-landing-decor'));
    gsap.timeline({ defaults: { ease, duration: 0.8 } })
        .fromTo(
            clipElements,
            {
                y: '-105%',
                stagger: 0.05,
            },
            {
                y: 0,
            },
        )
        .fromTo(
            decorElements,
            {
                scale: 0,
            },
            {
                scale: 1,
            },
            0.3,
        )
        .to(
            mapSvg,
            {
                scale: isPortrait ? 1 : 0.5,
                x: isPortrait ? 0 : '-25%',
                y: isPortrait ? 0 : '25%',
                opacity: 1,
                duration: 1,
            },
            0.85,
        );

    window.addEventListener('resize', checkMapState);
};

export const landingEnterFromMap = (container: HTMLElement) => {
    const clipElements = Array.from(container.querySelectorAll<HTMLElement>('.a-clip-element'));
    const decorElements = Array.from(container.querySelectorAll<HTMLElement>('.a-landing-decor'));

    gsap.timeline({ defaults: { ease, duration: 0.8 } })
        .to(mapCities, {
            opacity: 0,
        })
        .fromTo(
            clipElements,
            {
                y: '-105%',
                stagger: 0.05,
            },
            {
                y: 0,
            },
        )
        .fromTo(
            mapSvg,
            {
                opacity: isMobile ? 0 : 1,
            },
            {
                opacity: 1,
                scale: isPortrait ? 1 : 0.5,
                x: isPortrait ? 0 : '-25%',
                y: isPortrait ? 0 : '25%',

                duration: 0.67,
            },
            0.7,
        )
        .fromTo(
            decorElements,
            {
                scale: 0,
            },
            {
                scale: 1,
            },
            0.3,
        );

    window.addEventListener('resize', checkMapState);
};

export const landingLeaveToIndex = (container: HTMLElement, resolve: (value?: unknown) => void) => {
    const clipElements = Array.from(container.querySelectorAll<HTMLElement>('.a-clip-element'));
    const decorElements = Array.from(container.querySelectorAll<HTMLElement>('.a-landing-decor'));

    gsap.timeline({ defaults: { ease, duration: 0.8 } })
        .fromTo(
            clipElements,
            {
                y: 0,
                stagger: 0.05,
            },
            {
                y: '105%',
            },
            0.3,
        )
        .fromTo(
            decorElements,
            {
                scale: 1,
            },
            {
                scale: 0,
            },
            0.15,
        )
        .fromTo(
            mapSvg,
            {
                opacity: 1,
            },
            {
                opacity: 0,
            },
            0.5,
        )
        .then(resolve);
    window.removeEventListener('resize', checkMapState);
};

export const landingLeaveToMap = (container: HTMLElement, resolve: (value?: unknown) => void) => {
    const clipElements = Array.from(container.querySelectorAll<HTMLElement>('.a-clip-element'));
    const decorElements = Array.from(container.querySelectorAll<HTMLElement>('.a-landing-decor'));
    const header = container.querySelector<HTMLElement>('.a-landing-header');

    gsap.timeline({ defaults: { ease, duration: 0.8 } })
        .fromTo(
            clipElements,
            {
                y: 0,
                stagger: 0.05,
            },
            {
                y: '105%',
            },
            0.3,
        )
        .fromTo(
            decorElements,
            {
                scale: 1,
            },
            {
                scale: 0,
            },
            0.15,
        )
        .to(
            header,
            {
                opacity: 0,
            },
            0.2,
        )
        .fromTo(
            mapSvg,
            {
                scale: isPortrait ? 1 : 0.5,
                x: isPortrait ? 0 : '-25%',
                y: isPortrait ? 0 : '25%',
                opacity: 1,
            },
            {
                scale: 1,
                duration: 1.3,
                x: 0,
                y: 0,
                opacity: isMobile ? 0 : 1,
            },
            0.4,
        )
        .then(resolve);
    window.removeEventListener('resize', checkMapState);
};
