type VideoType = {
    webm: string;
    mov: string;
};

export type Slugs = 'zola' | 'zshm' | 'recycle';

type ProductionInfo = {
    title: string;
    slug: Slugs;
    subtitle?: string;
    description?: string;
    video: VideoType;
    products?: { title: string; description: string }[];
    scope?: {
        title: string;
        description: string;
    }[];
    areas?: {
        title: string;
        list: string[];
    }[];
};

export const productionData: ProductionInfo[] = [
    {
        title: 'Зола-уноса',
        slug: 'zola',
        subtitle:
            '— Продукт сжигания угля на электростанциях, который уносится дымовыми газами и собирается золоулавливающими устройствами',
        description: 'Зола-уноса является минеральной добавкой, применяемой в различных сферах промышленности',
        video: {
            webm: '/static/videos/zola_unosa_big.webm',
            mov: '/static/videos/zola_unosa_big.mov',
        },
        scope: [
            {
                title: 'Дорожное строительство',
                description:
                    'В качестве добавок для укрепления слабых грунтов; в качестве компонентов минерального вяжущего; при производстве бетонных смесей',
            },
            {
                title: 'Производство ячеистого бетона',
                description: 'В качестве кремнеземистого компонента смеси; в качестве вяжущего материала',
            },
            {
                title: 'Производство товарного бетона',
                description: 'В качестве минеральной добавки, частично заменяющей цемент',
            },
            {
                title: 'Производство ЖБИ',
                description:
                    'Служит наполнителем, улучшающим структурообразование бетона; способствует повышению удобоукладываемости и плотности бетонной смеси',
            },
            {
                title: 'Битум',
                description:
                    'В качестве минеральной добавки, повышая температуру перехода битума из вязкого состояния в жидкое',
            },
            {
                title: 'Производство сухих строительных смесей',
                description:
                    'В качестве наполнителя для полной или частичной замены формовочного песка или частичной замены вяжущего компонента',
            },
            {
                title: 'Производство цемента',
                description: 'В качестве компонента клинкера',
            },
        ],
        areas: [
            {
                title: 'Каменноугольная',
                list: ['Гранулометрическая добавка', 'Активная минеральная добавка в смеси с вяжущим'],
            },
            {
                title: 'Буроугольная',
                list: [
                    'Самостоятельное медленнотвердеющее вяжущее',
                    'Активная минеральная добавка',
                    'Применяется в работах по стабилизации грунтов земляного полотна',
                ],
            },
        ],
    },
    {
        title: 'Золошлаковые материалы',
        slug: 'zshm',
        subtitle:
            '— Смесь золы-уноса и топливного шлака, образовавшаяся при их совместном удалении в золоотвал и прошедшая процесс преобразования',
        description:
            'Золошлаковые материалы относятся к техногенных дисперсным несвязанным грунтам, подтип антропогенный (ГОСТ 25100-2020 «Грунты. Классификация»).',
        video: {
            webm: '/static/videos/zshm_big.webm',
            mov: '/static/videos/zshm_big.mov',
        },
        scope: [
            {
                title: 'Дорожное строительство',
                description:
                    'Для сооружения земляного полотна или устройства дополнительных слоев оснований дорожных одежд. Применение золошлаков снижает потребность в традиционных дорожно-строительных материалах и повышается эффективность их использования.',
            },
            {
                title: 'Вертикальная планировка территорий',
                description:
                    'Для выравнивания площадок, вертикальной планировки территорий, инженерной подготовки площадки под строительство',
            },
            {
                title: 'Рекультивация земель',
                description:
                    'В качестве замены природного грунта при восстановлении нарушенных земель, а также при отсыпке котлованов и выемок',
            },
            {
                title: 'Использование на полигонах отходов',
                description:
                    'В качестве материала для изолирующего слоя при эксплуатации полигона и подстилающего слоя при рекультивации полигона, а также для тушения скрытых очагов возгорания',
            },
        ],
    },
    {
        title: 'Продукты переработки',
        slug: 'recycle',
        products: [
            {
                title: 'Шлаковый щебень <br/>(фр. более 4 мм)',
                description:
                    'Используется для устройства оснований и в качестве заполнителя для бетонов и асфальтобетонов',
            },
            {
                title: 'Шлаковый песок <br/>(фр. менее 4 мм)',
                description:
                    'Используется в подстилающих слоях, а также в качестве противогололедного материала или заполнителя для бетонов и асфальтобетонов',
            },
        ],
        video: {
            webm: '/static/videos/recycle_big.webm',
            mov: '/static/videos/recycle_big.mov',
        },
    },
];
