import { h } from 'preact';
import React, { useState, useEffect, useRef } from 'react';
import type { PossibilitiesProjectInfo } from '@/data/projects';
import { AnimatePresence } from 'framer-motion';

import { PossibilitiesDetailContextProvider, usePossibilitiesDetailContext } from './PossibilitiesDetailContext';
import PossibilitiesDetailLayout from './PossibilitiesDetailLayout';
import PossibilitiesDetailProjectsList from './PossibilitiesDetailProjectsList';

type Props = {
    list: PossibilitiesProjectInfo[];
};

const PossibilitiesDetailLayoutWrapper = () => {
    const wrapperEl = useRef<HTMLDivElement>(null);
    const { projects, activeProjectSlug, setIsAnimating } = usePossibilitiesDetailContext();

    const [currentProject, setCurrentProject] = useState<PossibilitiesProjectInfo | null>(
        projects.find((project) => project.projectSlug === activeProjectSlug) ?? null,
    );

    useEffect(() => {
        setCurrentProject(projects.find((project) => project.projectSlug === activeProjectSlug) ?? null);
        if (wrapperEl.current) {
            wrapperEl.current.scrollTo({
                top: 0,
            });
        }
    }, [activeProjectSlug, projects]);

    return (
        currentProject && (
            <div className="experience-content" ref={wrapperEl}>
                <AnimatePresence
                    mode="wait"
                    onExitComplete={() => {
                        setIsAnimating(false);
                    }}
                >
                    <PossibilitiesDetailLayout {...currentProject} key={activeProjectSlug} />
                </AnimatePresence>
            </div>
        )
    );
};

const PossibilitiesDetailPopup = ({ list }: Props) => {
    return (
        <PossibilitiesDetailContextProvider projects={list}>
            <div className="experience-with-aside">
                <PossibilitiesDetailLayoutWrapper />
                <div className="experience-aside">
                    <PossibilitiesDetailProjectsList />
                </div>
            </div>
        </PossibilitiesDetailContextProvider>
    );
};

export default PossibilitiesDetailPopup;
