import { h } from 'preact';
import React, { HTMLAttributes } from 'react';
import { motion, useWillChange } from 'framer-motion';
import classNames from 'classnames';

const transition = {
    duration: 0.67,
    ease: [0.33, 0, 0.67, 1],
};

type Props = {
    slug?: string | boolean;
    children: string | h.JSX.Element;
    delay?: number;
    className?: string;
    tagName?: keyof JSX.IntrinsicElements;
};

const ClipReveal = ({ slug, delay = 0.1, children, className, tagName = 'div' }: Props) => {
    const willChange = useWillChange();
    const Component = tagName;

    return (
        <Component className={classNames('clip-wrapper', className)}>
            <motion.div
                initial={{ y: '-110%' }}
                animate={{
                    y: 0,
                    transition: {
                        delay: delay,
                        duration: 0.3,
                    },
                }}
                exit={{ y: '110%' }}
                transition={transition}
                key={slug}
                style={{ willChange }}
            >
                {children}
            </motion.div>
        </Component>
    );
};

export default ClipReveal;
