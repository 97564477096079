import { h } from 'preact';
import React from 'react';
import { CloseIcon } from '@/components/svg/CloseIcon';
import { citiesData } from '@/data/cities';
import { groupBy } from '@/utils/group-by';
import { motion } from 'framer-motion';
import { useCitiesAndTpsContext } from '../CitiesAndTpsContext';

const EXIT_DURATION = 0.5;

const ChooseCityView = () => {
    const { setView, setActiveCitySlug, setTpsViewCloseTarget } = useCitiesAndTpsContext();
    const regions = groupBy(citiesData, 'region');

    return (
        <div className="city-and-tps-choose-city">
            <div className="city-and-tps-choose-city-header">
                <motion.button
                    initial={{
                        opacity: 0,
                    }}
                    animate={{
                        opacity: 1,
                        transition: {
                            delay: 0.67,
                            duration: 0.67,
                        },
                    }}
                    exit={{
                        opacity: 0,
                        transition: {
                            duration: EXIT_DURATION,
                        },
                    }}
                    onClick={() => {
                        setView('closed');
                    }}
                    className="button button-close"
                >
                    <div className="button__inner">
                        <CloseIcon />
                    </div>
                    <div className="button__inner button__inner-copy">
                        <CloseIcon />
                    </div>
                </motion.button>
            </div>
            <motion.div
                initial={{
                    opacity: 0,
                }}
                animate={{
                    opacity: 1,
                    transition: {
                        delay: 0.67,
                        duration: 0.67,
                    },
                }}
                exit={{
                    opacity: 0,
                    transition: {
                        duration: EXIT_DURATION,
                    },
                }}
                className="cities-dropdown-list"
            >
                {Object.keys(regions).map((item, i) => {
                    return (
                        <div className="region-item-wrapper" key={i}>
                            <motion.div
                                initial={{
                                    y: '105%',
                                }}
                                animate={{
                                    y: 0,
                                    transition: {
                                        delay: 0.67,
                                        duration: 0.67,
                                    },
                                }}
                                exit={{
                                    y: '-105%',
                                    transition: {
                                        duration: EXIT_DURATION,
                                    },
                                }}
                                className="region-item"
                            >
                                <div className="region-item__title text-sm">{item}</div>
                                <div className="region-item__cities">
                                    {regions[item].map((city, j) => {
                                        return (
                                            <button
                                                className="button button-rounded-primary"
                                                disabled={city.tps.filter((tps) => !tps.disabled).length < 1}
                                                key={j}
                                                onClick={() => {
                                                    setActiveCitySlug(city.slug);
                                                    setTpsViewCloseTarget('choose-city');
                                                    setView('choose-tps');
                                                }}
                                            >
                                                <div className="button__inner">
                                                    <div className="button__text h-text-md">{city.name}</div>
                                                </div>
                                                <div className="button__inner button__inner-copy">
                                                    <div className="button__text h-text-md">{city.name}</div>
                                                </div>
                                            </button>
                                        );
                                    })}
                                </div>
                            </motion.div>
                        </div>
                    );
                })}
            </motion.div>
        </div>
    );
};

export default ChooseCityView;
