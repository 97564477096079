import { RefObject, useEffect } from 'react';

type Handler = (event: MouseEvent) => void;

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
    ref: RefObject<T>,
    handler: Handler,
    mouseEvent: 'mousedown' | 'mouseup' = 'mousedown',
    excludeSelector?: string,
): void {
    useEffect(() => {
        const onClick = (event: MouseEvent) => {
            const el = ref?.current;
            const eventTarget = event.target as HTMLElement;

            // Do nothing if clicking ref's element or descendent element
            if (!el || el.contains(event.target as Node) || (excludeSelector && eventTarget.closest(excludeSelector))) {
                return;
            }

            handler(event);
        };

        document.addEventListener(mouseEvent, onClick);

        return () => document.removeEventListener(mouseEvent, onClick);
    }, [handler, ref, mouseEvent, excludeSelector]);
}
