import { ITransitionPage } from '@barba/core';
import { landingLeaveToIndex } from '@/animations/landing-page-animations';
import { indexEnter } from '@/animations/index-page-animations';

export default {
    name: 'landing-to-index-transition',

    from: {
        namespace: 'landing-page',
    },

    to: {
        namespace: 'index-page',
    },

    enter() {
        window.scrollTo({ top: 0, behavior: 'auto' });
    },

    beforeLeave({ current }) {
        return new Promise((resolve) => {
            landingLeaveToIndex(current.container, resolve);
        });
    },

    afterEnter({ next }) {
        indexEnter(next.container);
    },
} as ITransitionPage;
