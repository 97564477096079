const ease = [0.33, 0, 0.67, 1];

export const titleVariants = {
    initial: {
        y: 105,
    },
    active: {
        y: 0,
        display: 'block',
        transition: {
            type: 'tween',
            ease,
            duration: 0.3,
            delay: 0.3,
        },
    },
    inactive: {
        y: -105,
        transition: {
            type: 'tween',
            ease,
            duration: 0.3,
        },
        transitionEnd: { display: 'none', y: 105 },
    },
};

export const videoVariants = {
    initial: {
        y: -20,
        opacity: 0,
        transition: {
            type: 'tween',
            ease,
            duration: 0.4,
        },
    },
    active: {
        y: 0,
        opacity: 1,
        display: 'block',
        transition: {
            type: 'tween',
            ease,
            duration: 0.4,
            delay: 0.4,
        },
    },
    inactive: {
        y: 20,
        opacity: 0,
        transition: {
            type: 'tween',
            ease,
            duration: 0.4,
        },
        transitionEnd: { display: 'none', y: 20, opacity: 0 },
    },
};

export const fadeInVariants = {
    initial: {
        opacity: 0,
        display: 'none',
        transition: {
            duration: 0.3,
        },
    },
    active: {
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: 0.6,
        },
        display: 'flex',
    },
    inactive: {
        opacity: 0,
        transition: {
            duration: 0.4,
        },
        transitionEnd: {
            display: 'none',
        },
    },
};

export const tabBodyVariants = {
    initial: {
        opacity: 0,
        display: 'none',
        transition: {
            duration: 0.3,
        },
    },
    animate: {
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: 0.4,
        },
        display: 'flex',
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.4,
        },
        transitionEnd: {
            display: 'none',
        },
    },
};

export const indicatorVariants = {
    initial: {
        x: 0,
    },
    zola: {
        x: 0,
    },
    zshm: {
        transform: 'translateX(calc(100% + 8px))',
    },
    recycle: {
        transform: `translateX(calc(200% + 16px))`,
    },
};
