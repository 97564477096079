import { h } from 'preact';
import React from 'react';

export const PauseSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="3" y="3" width="7" height="18" rx="3.5" fill="white" />
            <rect x="14" y="3" width="7" height="18" rx="3.5" fill="white" />
        </svg>
    );
};
