import ProductionTabs from '@/components/ProductionTabs/ProductionTabs';
import { h } from 'preact';
import { render } from 'preact';
import React from 'react';

function init(container: HTMLElement | Document = document) {
    const productionPopup = container.querySelector('app-lit-popup[data-lit-popup="production-popup"]');

    if (productionPopup) {
        const root = productionPopup.querySelector<HTMLElement>('.lit-popup-container');

        if (root) {
            render(<ProductionTabs />, root);
        }
    }
}
const _module = { init };

export default _module;
