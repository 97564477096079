import { ITransitionPage } from '@barba/core';
import { mapEnter } from '@/animations/map-page-animations';

export default {
    name: 'map-once-transition',

    to: {
        namespace: 'map-page',
    },

    once({ next }) {
        mapEnter(next.container);
    },
} as ITransitionPage;
