import { h } from 'preact';
import React, { useEffect, useState, useRef } from 'react';
import type { PossibilitiesProjectInfo } from '@/data/projects';
import { usePossibilitiesDetailContext } from './PossibilitiesDetailContext';
import { motion, AnimatePresence } from 'framer-motion';
import { SquareButtonArrowLeftIcon } from '../svg/SquareButtonArrowLeftIcon';
import { SquareButtonArrowRightIcon } from '../svg/SquareButtonArrowRightIcon';
import { getCSSCustomProp } from '@/utils/css';
import classNames from 'classnames';
import ClipReveal from '../ClipReveal/ClipReveal';
import { CloseIcon } from '../svg/CloseIcon';
import debounce from 'lodash.debounce';

const PossibilitiesDetailProjectsList = () => {
    const { activeProjectSlug, projects, setActiveProjectSlug, isAnimating, setIsAnimating, setProjects } =
        usePossibilitiesDetailContext();

    const isMobile = window.matchMedia('(max-width: 1023px)').matches;
    const offset = getCSSCustomProp(document.documentElement, '--offset-x', 'number') as number;

    const [isOtherProjectsShows, setIsOtherProjectsShows] = useState(false);
    const projectsRef = useRef<HTMLDivElement>(null);

    const pageDataLimit = 3;
    const [currPageNo, setCurrPageNo] = useState(1);
    const [currPageProjects, setCurrPageProjects] = useState<PossibilitiesProjectInfo[]>([]);

    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        const debouncedOnResize = debounce(() => {
            setViewportWidth(window.innerWidth);
        }, 50);

        setProjects((prevProjects: PossibilitiesProjectInfo[]) => {
            const updatedProjects = [...prevProjects];
            updatedProjects.pop();
            return updatedProjects;
        });

        window.addEventListener('resize', debouncedOnResize);

        return () => {
            window.removeEventListener('resize', debouncedOnResize);
        };
    }, []);

    useEffect(() => {
        const startIndex = currPageNo * pageDataLimit - pageDataLimit;
        const endIndex = startIndex + pageDataLimit;
        setCurrPageProjects(isMobile ? projects : projects.slice(startIndex, endIndex));
    }, [projects, currPageNo, isMobile, viewportWidth]);

    const nextPage = () => setCurrPageNo((prev) => prev + 1);
    const previousPage = () => setCurrPageNo((prev) => prev - 1);

    const variants = {
        hidden: {
            transition: {
                when: 'afterChildren',
            },
        },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
            },
        },
    };

    return (
        <div
            className={classNames('projects', {
                'is-open': isOtherProjectsShows,
            })}
            ref={projectsRef}
        >
            <button
                className="projects-others-button"
                onClick={() => {
                    setIsOtherProjectsShows(true);
                }}
            >
                <div className="projects-others-button__text">
                    <AnimatePresence mode="wait">
                        <ClipReveal delay={0} className="text-md" key={isOtherProjectsShows}>
                            {isOtherProjectsShows ? 'Выберите проект' : 'Выбрать другой проект'}
                        </ClipReveal>
                    </AnimatePresence>
                </div>
                <AnimatePresence mode="wait">
                    {isOtherProjectsShows ? (
                        <motion.button
                            key={isOtherProjectsShows}
                            initial={{
                                opacity: 0,
                            }}
                            animate={{
                                opacity: 1,
                                transition: {
                                    delay: 0.3,
                                    duration: 0.3,
                                },
                            }}
                            exit={{
                                opacity: 0,
                                transition: {
                                    duration: 0.3,
                                },
                            }}
                            aria-label="Закрыть"
                            onClick={(event: Event) => {
                                event.stopPropagation();
                                setIsOtherProjectsShows(false);
                            }}
                            className="button button-close"
                        >
                            <div className="button__inner">
                                <CloseIcon />
                            </div>
                            <div className="button__inner button__inner-copy">
                                <CloseIcon />
                            </div>
                        </motion.button>
                    ) : (
                        <motion.div
                            initial={{
                                opacity: 0,
                            }}
                            animate={{
                                opacity: 1,
                                transition: {
                                    delay: 0.3,
                                    duration: 0.3,
                                },
                            }}
                            exit={{
                                opacity: 0,
                                transition: {
                                    duration: 0.3,
                                },
                            }}
                            className="animated-circles"
                            key={isOtherProjectsShows}
                        >
                            <div className="circle"></div>
                            <div className="circle"></div>
                            <div className="circle"></div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </button>
            <motion.div className="projects-list" variants={variants} initial="hidden" animate="show">
                {currPageProjects.length > 0 &&
                    currPageProjects.map((item, i) => {
                        return (
                            <motion.button
                                variants={{
                                    hidden: {
                                        opacity: 0,
                                        x: 50,
                                        transition: {
                                            duration: 0.8,
                                            delay: 0.2 * i,
                                        },
                                    },
                                    show: {
                                        opacity: 1,
                                        x: 0,
                                        transition: {
                                            duration: 0.4,
                                            delay: 0.15 * i,
                                        },
                                    },
                                }}
                                key={item.projectSlug}
                                className="projects-list__item project-item"
                                disabled={isAnimating || activeProjectSlug === item.projectSlug}
                                onClick={() => {
                                    setActiveProjectSlug(item.projectSlug);
                                    if (activeProjectSlug !== item.projectSlug) {
                                        setIsAnimating(true);
                                        setIsOtherProjectsShows(false);
                                    }
                                }}
                            >
                                <div className="project-item__image">
                                    <picture>
                                        <source
                                            media="(max-width: 767px)"
                                            srcSet={`/static/img/possibilities-detail/${item.image}-sm@resize-360x.webp`}
                                            type="image/webp"
                                        />
                                        <source
                                            media="(max-width: 1023px)"
                                            srcSet={`/static/img/possibilities-detail/${item.image}-sm@resize-400x.webp`}
                                            type="image/webp"
                                        />
                                        <source
                                            media="(max-width: 1279px)"
                                            srcSet={`/static/img/possibilities-detail/${item.image}-sm@resize-460x.webp`}
                                            type="image/webp"
                                        />
                                        <source
                                            media="(max-width: 1920px)"
                                            srcSet={`/static/img/possibilities-detail/${item.image}-sm@resize-600x.webp`}
                                            type="image/webp"
                                        />
                                        <source
                                            media="(min-width: 1921px)"
                                            srcSet={`/static/img/possibilities-detail/${item.image}-sm@resize-708x.webp`}
                                            type="image/webp"
                                        />
                                        <source
                                            srcSet={`/static/img/possibilities-detail/${item.image}-sm@resize-2600x.webp`}
                                            type="image/webp"
                                        />
                                        <source
                                            media="(max-width: 767px)"
                                            srcSet={`/static/img/possibilities-detail/${item.image}-sm@resize-360x.jpg`}
                                        />
                                        <source
                                            media="(max-width: 1023px)"
                                            srcSet={`/static/img/possibilities-detail/${item.image}-sm@resize-400x.jpg`}
                                        />
                                        <source
                                            media="(max-width: 1279px)"
                                            srcSet={`/static/img/possibilities-detail/${item.image}-sm@resize-460x.jpg`}
                                        />
                                        <source
                                            media="(max-width: 1920px)"
                                            srcSet={`/static/img/possibilities-detail/${item.image}-sm@resize-600x.jpg`}
                                        />
                                        <source
                                            media="(min-width: 1921px)"
                                            srcSet={`/static/img/possibilities-detail/${item.image}-sm@resize-708x.jpg`}
                                        />
                                        <img
                                            alt={item.title}
                                            src={`/static/img/possibilities-detail/${item.image}-sm@resize-2600x.jpg`}
                                            width="2416"
                                            height="1812"
                                        />
                                    </picture>
                                </div>
                                {activeProjectSlug === item.projectSlug && (
                                    <motion.div
                                        variants={{
                                            hidden: {
                                                opacity: 0,
                                                y: 5,
                                            },
                                            show: {
                                                opacity: 1,
                                                y: 0,
                                            },
                                        }}
                                        initial="hidden"
                                        animate="show"
                                        className="project-badge"
                                    >
                                        <div className="project-badge__decor"></div>
                                        <div className="project-badge__text text-sm">Выбрано</div>
                                    </motion.div>
                                )}
                                <div className="project-item__text">{item.title}</div>
                            </motion.button>
                        );
                    })}
            </motion.div>

            <div className="projects-nav">
                <button className="button button-square" onClick={previousPage} disabled={currPageNo === 1}>
                    <div className="button__inner">
                        <SquareButtonArrowLeftIcon />
                    </div>
                    <div className="button__inner button__inner-copy">
                        <SquareButtonArrowLeftIcon />
                    </div>
                </button>
                <div className="text-md">Другие проекты</div>
                <button
                    className="button button-square is-next"
                    onClick={nextPage}
                    disabled={currPageNo === Math.round(projects.length / pageDataLimit)}
                >
                    <div className="button__inner">
                        <SquareButtonArrowRightIcon />
                    </div>
                    <div className="button__inner button__inner-copy">
                        <SquareButtonArrowRightIcon />
                    </div>
                </button>
            </div>
        </div>
    );
};

export default PossibilitiesDetailProjectsList;
