// eslint-disable-next-line
import { Fragment, h } from 'preact';
import { productionData, Slugs } from '@/data/production';
import { documents } from '@/data/documents';
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { LitPopupElement } from '@/custom-elements/LitPopupElement/LitPopupElement';
import { motion, AnimatePresence } from 'framer-motion';
import classNames from 'classnames';
import {
    titleVariants,
    videoVariants,
    fadeInVariants,
    tabBodyVariants,
    indicatorVariants,
} from './production-tabs-animation-variants';
import { tp } from '@/typograf';

import { CloseIcon } from '../svg/CloseIcon';
import { GradientArrowIcon } from '../svg/GradientArrowIcon';
import { WhiteArrowIcon } from '../svg/WhiteArrowIcon';

const ProductionTabs = () => {
    const [activeSlug, setActiveSlug] = useState<Slugs>('zola');
    const [showDescription, setShowDescription] = useState(true);
    const [showDocuments, setShowDocuments] = useState(false);
    const videosRef = useRef<HTMLVideoElement[]>([]);
    const productionRef = useRef<HTMLDivElement>(null);
    const videoWrapperRef = useRef<HTMLDivElement>(null);
    const documentsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const videos = videosRef.current;
        videos.forEach((video) => {
            if (video.dataset.slug === activeSlug && showDescription && !showDocuments) {
                video.play();
            } else {
                video.pause();
            }
        });

        if (productionRef.current) {
            productionRef.current.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        if (showDocuments) {
            setShowDescription(false);
        }

        document.documentElement.style.setProperty(
            '--production-popup-offset',
            showDescription && !showDocuments
                ? ''
                : videoWrapperRef.current?.offsetHeight
                ? `${videoWrapperRef.current?.offsetHeight / 4}px`
                : '0px',
        );

        return () => {
            videos.forEach((video) => video.pause());
            document.documentElement.style.setProperty('--production-popup-offset', '');
        };
    }, [activeSlug, showDescription, showDocuments, documentsRef.current]);

    useEffect(() => {
        const productionPopup = document.querySelector<LitPopupElement>(
            'app-lit-popup[data-lit-popup="production-popup"]',
        );

        const onPopupOpen = (event: CustomEvent) => {
            const triggerElement = event.detail.triggerElement;
            const slug = triggerElement.dataset.slug;
            setActiveSlug(slug);
        };

        const onPopupClose = () => {
            if (videosRef.current) {
                videosRef.current.forEach((video) => video.pause());
            }
            setShowDescription(true);
        };

        if (productionPopup) {
            productionPopup.addEventListener('open', onPopupOpen);
            productionPopup.addEventListener('close-complete', onPopupClose);
        }

        return () => {
            if (productionPopup) {
                productionPopup.removeEventListener('open', onPopupOpen);
                productionPopup.removeEventListener('close-complete', onPopupClose);
            }
        };
    }, []);

    return (
        <Fragment>
            <div className="production-videos" ref={videoWrapperRef}>
                {productionData.map((item, i) => {
                    return (
                        <motion.div
                            className="production-video"
                            key={i}
                            variants={videoVariants}
                            animate={activeSlug === item.slug && showDescription ? 'active' : 'inactive'}
                        >
                            <video
                                playsInline
                                muted
                                loop
                                ref={(el) => {
                                    if (el) {
                                        videosRef.current[i] = el;
                                    }
                                }}
                                data-slug={item.slug}
                            >
                                <source src={item.video.mov} type="video/mp4; codecs=hvc1" />
                                <source src={item.video.webm} type="video/webm; codecs=vp9" />
                            </video>
                        </motion.div>
                    );
                })}
            </div>

            <button
                className="production-close-button button button-close"
                data-lit-popup-close="production-popup"
                aria-label="закрыть"
            >
                <div className="button__inner">
                    <CloseIcon />
                </div>
                <div className="button__inner button__inner-copy">
                    <CloseIcon />
                </div>
            </button>

            <motion.button
                variants={fadeInVariants}
                animate={showDescription ? 'inactive' : 'active'}
                className={classNames(
                    'button-with-round-icon production-back-to-description button-with-round-icon--primary',
                )}
                onClick={() => {
                    setShowDescription(true);
                    setShowDocuments(false);
                }}
            >
                <div className="button__icon">
                    <GradientArrowIcon />
                </div>
                <div className="button__text text-sm">
                    Вернуться
                    <br />к описанию
                </div>
                <div className="button__text button__text-copy text-sm">
                    Вернуться
                    <br />к описанию
                </div>
            </motion.button>

            <div
                className={classNames('production-header', {
                    'production-header--show-description': !showDescription,
                })}
            >
                <div
                    className={classNames('production-title h2', {
                        'production-title-documents': showDocuments,
                    })}
                >
                    {productionData.map((item, i) => {
                        return (
                            <motion.span
                                variants={titleVariants}
                                animate={activeSlug === item.slug ? 'active' : 'inactive'}
                                className="js-production-title production-title-documents"
                                key={i}
                            >
                                {showDocuments ? 'Нормативная документация' : tp(item.title)}
                            </motion.span>
                        );
                    })}
                </div>

                <div className="production-nav">
                    {productionData.map((item, i) => {
                        return (
                            <button
                                className={classNames(
                                    'button production-nav__item production-nav-item js-production-tab-control',
                                    {
                                        'is-active': item.slug === activeSlug,
                                    },
                                )}
                                key={i}
                                onClick={() => {
                                    setActiveSlug(item.slug);
                                    if (item.slug === 'recycle' && showDescription === false) {
                                        setShowDescription(true);
                                    }
                                }}
                            >
                                <div className="button__text text-sm">{tp(item.title)}</div>
                            </button>
                        );
                    })}

                    <motion.div
                        variants={indicatorVariants}
                        animate={() => {
                            return activeSlug;
                        }}
                        className="production-nav-indicator js-production-nav-indicator"
                    ></motion.div>
                </div>
            </div>
            <div className="production-container" ref={productionRef}>
                <AnimatePresence>
                    {showDescription && (
                        <div className="production-change-to-use-case">
                            {activeSlug !== 'recycle' && (
                                <motion.button
                                    className="button-with-round-icon button-with-round-icon--primary"
                                    variants={fadeInVariants}
                                    animate={'active'}
                                    key={activeSlug}
                                    initial={'inactive'}
                                    onClick={() => {
                                        setShowDescription(false);
                                        setShowDocuments(false);
                                    }}
                                >
                                    <div className="button__icon">
                                        <WhiteArrowIcon />
                                    </div>
                                    <div className="button__text text-sm">
                                        смотреть
                                        <br />
                                        Сферы применения
                                    </div>
                                    <div className="button__text button__text-copy text-sm">
                                        смотреть
                                        <br />
                                        Сферы применения
                                    </div>
                                </motion.button>
                            )}

                            <motion.button
                                className="button-with-round-icon button-with-round-icon--primary"
                                variants={fadeInVariants}
                                animate={'active'}
                                initial={'inactive'}
                                key={activeSlug}
                                onClick={() => {
                                    setShowDescription(false);
                                    setShowDocuments(true);
                                }}
                            >
                                <div className="button__icon">
                                    <WhiteArrowIcon />
                                </div>
                                <div className="button__text text-sm">
                                    Нормативные
                                    <br />
                                    документы
                                </div>
                                <div className="button__text button__text-copy text-sm">
                                    Нормативные
                                    <br />
                                    документы
                                </div>
                            </motion.button>
                        </div>
                    )}
                </AnimatePresence>
                <AnimatePresence>
                    {showDocuments
                        ? documents.map(
                              (item, i) =>
                                  activeSlug === item.slug && (
                                      <motion.div
                                          {...tabBodyVariants}
                                          className="production-body"
                                          key="documents"
                                          ref={documentsRef}
                                      >
                                          <div className={classNames('production-scope')}>
                                              {item.scope &&
                                                  item.scope.map((scopeItem, index) => (
                                                      <div className="production-scope__item scope" key={index}>
                                                          <div className="scope__title h-text-lg">
                                                              {tp(scopeItem.title)}
                                                          </div>
                                                          <div className="scope__description text-lg">
                                                              {tp(scopeItem.description)}
                                                          </div>
                                                      </div>
                                                  ))}
                                          </div>
                                      </motion.div>
                                  ),
                          )
                        : showDescription
                        ? productionData.map(
                              (item, i) =>
                                  activeSlug === item.slug && (
                                      <motion.div {...tabBodyVariants} className="production-body" key={i}>
                                          <div className="production-footer">
                                              {item.subtitle && (
                                                  <div
                                                      className={classNames('production-subtitle text-xxl', item.slug)}
                                                  >
                                                      {tp(item.subtitle)}
                                                  </div>
                                              )}
                                              {item.description && (
                                                  <div className="production-description text-lg">
                                                      {tp(item.description)}
                                                  </div>
                                              )}
                                              {item.products &&
                                                  item.products.length > 0 &&
                                                  item.products.map((product, index) => (
                                                      <div className="product-item" key={index}>
                                                          <div
                                                              className="product-item__title h4"
                                                              dangerouslySetInnerHTML={{ __html: tp(product.title) }}
                                                          />
                                                          <div
                                                              className="product-item__description text-lg"
                                                              dangerouslySetInnerHTML={{
                                                                  __html: tp(product.description),
                                                              }}
                                                          />
                                                      </div>
                                                  ))}
                                          </div>
                                      </motion.div>
                                  ),
                          )
                        : productionData.map(
                              (item, i) =>
                                  activeSlug === item.slug && (
                                      <motion.div
                                          {...tabBodyVariants}
                                          key={i + productionData.length}
                                          className="production-scope-wrapper"
                                      >
                                          <div className="production-areas">
                                              {item.areas &&
                                                  item.areas.length > 0 &&
                                                  item.areas.map((area, j) => (
                                                      <div key={j} className="production-areas-item">
                                                          <div className="h4">{tp(area.title)}</div>
                                                          <ul className="list-unstyled gradient-list production-areas-list">
                                                              {area.list.length > 0 &&
                                                                  area.list.map((listItem, k) => (
                                                                      <li key={k} className="text-lg">
                                                                          {tp(listItem)}
                                                                      </li>
                                                                  ))}
                                                          </ul>
                                                      </div>
                                                  ))}
                                          </div>
                                          <div className={classNames('production-scope', item.slug)}>
                                              {item.scope &&
                                                  item.scope.map((scopeItem, index) => (
                                                      <div className="production-scope__item scope" key={index}>
                                                          <div className="scope__title h-text-lg">
                                                              {tp(scopeItem.title)}
                                                          </div>
                                                          <div className="scope__description text-lg">
                                                              {tp(scopeItem.description)}
                                                          </div>
                                                      </div>
                                                  ))}
                                          </div>
                                      </motion.div>
                                  ),
                          )}
                </AnimatePresence>
            </div>
        </Fragment>
    );
};

export default ProductionTabs;
