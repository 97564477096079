import { h } from 'preact';
import React from 'react';

export const MinusSvg = () => {
    return (
        <svg width="6" height="2" viewBox="0 0 6 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.08838 1.91895H0.917969V0.186035H5.08838V1.91895Z" fill="white" />
        </svg>
    );
};
