import { ITransitionPage } from '@barba/core';
import { landingEnter } from '@/animations/landing-page-animations';
import { indexLeave } from '@/animations/index-page-animations';

export default {
    name: 'index-to-landing-transition',

    from: {
        namespace: 'index-page',
    },

    to: {
        namespace: 'landing-page',
    },

    enter() {
        window.scrollTo({ top: 0, behavior: 'auto' });
    },

    beforeLeave({ current }) {
        return new Promise((resolve) => {
            indexLeave(current.container, resolve);
        });
    },

    afterEnter({ next }) {
        setTimeout(() => {
            landingEnter(next.container);
        }, 500);
    },
} as ITransitionPage;
