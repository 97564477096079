import { h } from 'preact';
import React from 'react';
import { motion, useWillChange } from 'framer-motion';
import CountUp from 'react-countup';
import type { ProjectInfo } from '@/data/projects';
import classNames from 'classnames';
import { CloseIcon } from '../svg/CloseIcon';
import { tp } from '@/typograf';
import { countDecimals } from '@/utils/strings';
import ClipReveal from '../ClipReveal/ClipReveal';
import { PlayColoredSvg } from '../svg/PlayColoredSvg';

const ExperienceLayout = ({
    slug,
    material,
    status,
    title,
    valueOfMaterialsPreLabel,
    valueOfMaterialsLabel,
    valueOfMaterials,
    valueOfMaterialsUnits,
    description,
    subtitle,
    stageList,
    footerTitle,
    caption,
    economic,
    image,
    template,
    video,
}: ProjectInfo) => {
    const willChange = useWillChange();
    return (
        <motion.div className="tps-project">
            <button
                className="tps-close-button button button-close"
                aria-label="Закрыть"
                data-lit-popup-close="experience-popup"
            >
                <div className="button__inner">
                    <CloseIcon />
                </div>
                <div className="button__inner button__inner-copy">
                    <CloseIcon />
                </div>
            </button>

            <div className="tps-content">
                <div className="tps-image">
                    <motion.picture
                        initial={{ y: '-105%' }}
                        animate={{
                            y: 0,
                            transition: {
                                delay: 0.1,
                                duration: 0.67,
                                ease: [0.33, 0, 0.67, 1],
                            },
                        }}
                        exit={{ y: '105%' }}
                        key={slug}
                        style={{ willChange }}
                    >
                        <source
                            media="(max-width: 1279px)"
                            srcSet={`/static/img/projects/${image}@resize-1000x.webp`}
                            type="image/webp"
                        />
                        <source
                            media="(max-width: 1920px)"
                            srcSet={`/static/img/projects/${image}@resize-1900x.webp`}
                            type="image/webp"
                        />
                        <source
                            media="(min-width: 1921px)"
                            srcSet={`/static/img/projects/${image}@resize-2500x.webp`}
                            type="image/webp"
                        />
                        <source srcSet={`/static/img/projects/${image}@resize-2500x.webp`} type="image/webp" />
                        <source media="(max-width: 1279px)" srcSet={`/static/img/projects/${image}@resize-1000x.jpg`} />
                        <source media="(max-width: 1920px)" srcSet={`/static/img/projects/${image}@resize-1900x.jpg`} />
                        <source media="(min-width: 1921px)" srcSet={`/static/img/projects/${image}@resize-2500x.jpg`} />
                        <img
                            alt={`Фотография - ${title}`}
                            src={`/static/img/projects/${image}@resize-2500x.jpg`}
                            width="2416"
                            height="1812"
                        />
                    </motion.picture>
                    <div className="project-badges-and-video">
                        <div className="project-badges">
                            <ClipReveal slug={slug} delay={0.2}>
                                <div className="badge text-sm">
                                    <div className="badge__label">Материал:</div>
                                    <div className="badge__value">{tp(material)}</div>
                                </div>
                            </ClipReveal>

                            <ClipReveal slug={slug} delay={0.2}>
                                <div className="badge text-sm">
                                    <div className="badge__label">Статус:</div>
                                    <div className="badge__value">{tp(status)}</div>
                                </div>
                            </ClipReveal>
                        </div>

                        {video && (
                            <ClipReveal delay={0.3}>
                                <button className="project-video-button" data-lit-popup-open={video.popupName}>
                                    <div className="project-video-button__left text-sm">
                                        <div className="project-video-button__info">Видео · {video.duration}</div>
                                        <div className="project-video-button__name">{video.name}</div>
                                    </div>
                                    <div className="project-video-button__icon">
                                        <PlayColoredSvg />
                                    </div>
                                </button>
                            </ClipReveal>
                        )}
                    </div>
                </div>
                <div className={classNames('project-body', template)}>
                    <div className="project-left">
                        <div className="project-title h4">
                            <ClipReveal slug={slug} delay={0.3}>
                                {tp(title)}
                            </ClipReveal>
                        </div>
                        {description && template !== 'template-two' && (
                            <ClipReveal slug={slug}>
                                <div
                                    className="project-description text-lg"
                                    dangerouslySetInnerHTML={{ __html: tp(description) }}
                                />
                            </ClipReveal>
                        )}
                        <div className="project-value">
                            <div className="project-value__label text-sm">
                                <ClipReveal slug={slug} delay={0.4}>
                                    {tp(valueOfMaterialsLabel)}
                                </ClipReveal>
                            </div>

                            <ClipReveal slug={slug} delay={0.4}>
                                <div
                                    className={classNames('project-value__value h3', {
                                        'project-value-with-pre': valueOfMaterialsPreLabel,
                                    })}
                                >
                                    {valueOfMaterialsPreLabel && (
                                        <div className="project-value__prelabel">{valueOfMaterialsPreLabel}</div>
                                    )}
                                    <div className="project-value__num">
                                        <CountUp
                                            start={0}
                                            delay={0.4}
                                            end={valueOfMaterials}
                                            decimals={countDecimals(valueOfMaterials)}
                                            decimal=","
                                            separator=" "
                                        />
                                    </div>
                                    <div className="project-value__units">{valueOfMaterialsUnits}</div>
                                </div>
                            </ClipReveal>
                        </div>
                    </div>

                    <div className="project-right">
                        {subtitle && (
                            <div className="project-subtitle h4">
                                <ClipReveal slug={slug} delay={0.4}>
                                    {tp(subtitle)}
                                </ClipReveal>
                            </div>
                        )}
                        {description && template === 'template-two' && (
                            <ClipReveal slug={slug} delay={0.4}>
                                <div
                                    className="project-description text-lg"
                                    dangerouslySetInnerHTML={{ __html: tp(description) }}
                                />
                            </ClipReveal>
                        )}
                        {economic && economic.length > 0 && (
                            <ul className="project-economic list-unstyled">
                                {economic.map((item, i) => {
                                    return (
                                        <ClipReveal slug={slug} key={i} delay={0.4}>
                                            <li className="project-economic-item">
                                                <div className="project-economic-item-value h-text-lg">
                                                    {item.valuePreLabel && (
                                                        <div className="project-economic-item-value__pre">
                                                            {item.valuePreLabel}
                                                        </div>
                                                    )}
                                                    <div className="project-economic-item-value__num">
                                                        <CountUp
                                                            start={0}
                                                            delay={0.4}
                                                            decimals={countDecimals(item.value)}
                                                            decimal=","
                                                            end={item.value}
                                                            separator=" "
                                                        />
                                                    </div>
                                                    <div className="project-economic-item-value__units">
                                                        {item.units}
                                                    </div>
                                                </div>
                                                <div className="project-economic-item-label text-sm">
                                                    {tp(item.valueLabel)}
                                                </div>
                                            </li>
                                        </ClipReveal>
                                    );
                                })}
                            </ul>
                        )}
                        {stageList && stageList.length > 0 && (
                            <ul className="project-stage-list gradient-list list-unstyled">
                                {stageList.map((item, i) => {
                                    return (
                                        <ClipReveal slug={slug} key={i} delay={0.4}>
                                            <li className="text-lg">{tp(item)}</li>
                                        </ClipReveal>
                                    );
                                })}
                            </ul>
                        )}
                        {footerTitle && (
                            <div
                                className={classNames('project-footer-title text-xl', {
                                    'project-footer-title-top': image === 'item-7',
                                })}
                            >
                                <ClipReveal slug={slug} delay={0.4}>
                                    {tp(footerTitle)}
                                </ClipReveal>
                            </div>
                        )}
                        {caption && (
                            <div className="project-caption text-sm">
                                <ClipReveal slug={slug} delay={0.4}>
                                    {tp(caption)}
                                </ClipReveal>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default ExperienceLayout;
