import { h } from 'preact';
import React from 'react';

export const PlaySvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.8758 10.7541C22.2416 11.5174 22.2416 13.4826 20.8758 14.2459L9.97564 20.3371C8.6425 21.0821 7 20.1184 7 18.5913L7 6.40875C7 4.88157 8.64251 3.91787 9.97564 4.66286L20.8758 10.7541Z"
                fill="#EDF0F7"
            />
        </svg>
    );
};
