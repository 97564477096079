import { h } from 'preact';
import React from 'react';

export const GradientArrowIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15 6L9 12L15 18"
                stroke="url(#gradient-arrow)"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="gradient-arrow"
                    x1="10.1281"
                    y1="8.02778"
                    x2="14.0637"
                    y2="8.10988"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E34643" />
                    <stop offset="1" stopColor="#EA673E" />
                </linearGradient>
            </defs>
        </svg>
    );
};
