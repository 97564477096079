import { h } from 'preact';
import React, { useState, useEffect, useRef } from 'react';
import type { ProjectInfo } from '@/data/projects';
import { AnimatePresence } from 'framer-motion';

import { ExperienceContextProvider, useExperienceContext } from './ExperienceContext';
import ExperienceLayout from './ExperienceLayout';
import ExperienceProjectsList from './ExperienceProjectsList';

type Props = {
    list: ProjectInfo[];
};

const ExperienceLayoutWrapper = () => {
    const wrapperEl = useRef<HTMLDivElement>(null);
    const { projects, activeProjectSlug, setIsAnimating } = useExperienceContext();

    const [currentProject, setCurrentProject] = useState<ProjectInfo | null>(
        projects.find((project) => project.projectSlug === activeProjectSlug) ?? null,
    );

    useEffect(() => {
        setCurrentProject(projects.find((project) => project.projectSlug === activeProjectSlug) ?? null);
        if (wrapperEl.current) {
            wrapperEl.current.scrollTo({
                top: 0,
            });
        }
    }, [activeProjectSlug, projects]);

    return (
        currentProject && (
            <div className="experience-content" ref={wrapperEl}>
                <AnimatePresence
                    mode="wait"
                    onExitComplete={() => {
                        setIsAnimating(false);
                    }}
                >
                    <ExperienceLayout {...currentProject} key={activeProjectSlug} />
                </AnimatePresence>
            </div>
        )
    );
};

const ExperiencePopup = ({ list }: Props) => {
    return (
        <ExperienceContextProvider projects={list}>
            <div className="experience-with-aside">
                <ExperienceLayoutWrapper />
                <div className="experience-aside">
                    <ExperienceProjectsList />
                </div>
            </div>
        </ExperienceContextProvider>
    );
};

export default ExperiencePopup;
