import { h } from 'preact';
import React from 'react';

export const DoubleArrowRightSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 6L20 12L14 18"
                stroke="url(#paint0_linear_163_115)"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4 6L10 12L4 18"
                stroke="url(#paint1_linear_163_114)"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_163_115"
                    x1="11.5713"
                    y1="6"
                    x2="8.85351"
                    y2="13.9005"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E34643" />
                    <stop offset="1" stopColor="#EA673E" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_163_114"
                    x1="1.57129"
                    y1="6"
                    x2="-1.14649"
                    y2="13.9005"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E34643" />
                    <stop offset="1" stopColor="#EA673E" />
                </linearGradient>
            </defs>
        </svg>
    );
};
