import SafetyPopup from '@/components/SafetyPopup/SafetyPopup';
import { LitPopupElement } from '@/custom-elements/LitPopupElement/LitPopupElement';
import { h } from 'preact';
import React from 'react';
import { render } from 'preact';
import { projectsData } from '@/data/projects';

const onOpen = (event) => {
    const popup = event.target as HTMLElement;

    const root = popup.querySelector<HTMLElement>('.lit-popup-container');
    if (root) {
        render(<SafetyPopup list={projectsData} />, root);
    }
};

const onClose = (event) => {
    const popup = event.target as HTMLElement;
    const root = popup.querySelector<HTMLElement>('.lit-popup-container');
    if (root) {
        render('', root);
    }
};

function init(container: HTMLElement | Document = document) {
    const tpsPopup = container.querySelector<LitPopupElement>('[data-lit-popup="safety-popup"]');

    if (tpsPopup) {
        tpsPopup.addEventListener('open-complete', onOpen);
        tpsPopup.addEventListener('close-complete', onClose);
    }
}

function destroy(container: HTMLElement | Document = document) {
    const tpsPopup = container.querySelector<LitPopupElement>('[data-lit-popup="tps-popup"]');

    if (tpsPopup) {
        tpsPopup.removeEventListener('open-complete', onOpen);
        tpsPopup.removeEventListener('close-complete', onClose);
    }
}

const _module = { init, destroy };

export default _module;
