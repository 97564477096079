function onClick(event: MouseEvent) {
    const eventTarget = event.target as HTMLButtonElement;
    const target = eventTarget.closest<HTMLButtonElement>('.js-ripple-btn');

    if (target) {
        if (target.disabled) {
            return;
        }
        const addDiv = document.createElement('div');
        const mValue = Math.max(target.clientWidth, target.clientHeight) * 2;
        const rect = target.getBoundingClientRect();
        const sDiv = addDiv.style;
        const px = 'px';
        const container = target.querySelector('.button__bg');
        sDiv.width = sDiv.height = mValue + px;
        sDiv.left = event.clientX - rect.left - mValue / 2 + px;
        sDiv.top = event.clientY - rect.top - mValue / 2 + px;
        addDiv.classList.add('pulse');

        if (container) {
            container.appendChild(addDiv);

            setTimeout(() => {
                addDiv.remove();
            }, 1400);
        }
    }
}

function init(container: HTMLElement | Document = document) {
    const rippleButtons = Array.from(container.querySelectorAll<HTMLElement>('.js-ripple-btn'));

    rippleButtons.forEach((button) => {
        button.addEventListener('click', onClick);
    });
}

function destroy(container: HTMLElement | Document = document) {
    const rippleButtons = Array.from(container.querySelectorAll<HTMLElement>('.js-ripple-btn'));

    rippleButtons.forEach((button) => {
        button.removeEventListener('click', onClick);
    });
}

const _module = { init, destroy };

export default _module;
