import { h } from 'preact';
import React from 'react';

export const PlayColoredSvg = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.0031 7.66872C16.6708 8.11384 17.0046 8.33641 17.1209 8.61693C17.2227 8.86218 17.2227 9.13782 17.1209 9.38307C17.0046 9.6636 16.6708 9.88616 16.0031 10.3313L6.98752 16.3417C6.17946 16.8804 5.77544 17.1497 5.4404 17.1297C5.14852 17.1123 4.87889 16.968 4.70249 16.7348C4.5 16.4671 4.5 15.9815 4.5 15.0104L4.5 2.98963C4.5 2.01846 4.5 1.53288 4.70249 1.26521C4.8789 1.03202 5.14852 0.887722 5.4404 0.870295C5.77544 0.850291 6.17946 1.11964 6.98752 1.65835L16.0031 7.66872Z"
                fill="url(#paint0_linear_127_328)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_127_328"
                    x1="16.5296"
                    y1="0.0479134"
                    x2="6.2541"
                    y2="0.549164"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E34643" />
                    <stop offset="1" stopColor="#EA673E" />
                </linearGradient>
            </defs>
        </svg>
    );
};
