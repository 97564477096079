import { h } from 'preact';
import React, { useState, useEffect, useRef } from 'react';
import type { ProjectInfo } from '@/data/projects';
import { AnimatePresence } from 'framer-motion';

import { SafetyContextProvider, useSafetyContext } from './SafetyContext';
import SafetyLayout from './SafetyLayout';

type Props = {
    list: ProjectInfo[];
};

const SafetyLayoutWrapper = () => {
    const wrapperEl = useRef<HTMLDivElement>(null);
    const { projects, activeProjectSlug, setIsAnimating } = useSafetyContext();

    const [currentProject, setCurrentProject] = useState<ProjectInfo | null>(
        projects.find((project) => project.projectSlug === activeProjectSlug) ?? null,
    );

    useEffect(() => {
        setCurrentProject(projects.find((project) => project.projectSlug === activeProjectSlug) ?? null);
        if (wrapperEl.current) {
            wrapperEl.current.scrollTo({
                top: 0,
            });
        }
    }, [activeProjectSlug, projects]);

    return (
        currentProject && (
            <div className="safety-content" ref={wrapperEl}>
                <AnimatePresence
                    mode="wait"
                    onExitComplete={() => {
                        setIsAnimating(false);
                    }}
                >
                    <SafetyLayout {...currentProject} key={activeProjectSlug} />
                </AnimatePresence>
            </div>
        )
    );
};

const SafetyPopup = ({ list }: Props) => {
    return (
        <SafetyContextProvider projects={list}>
            <SafetyLayoutWrapper />
        </SafetyContextProvider>
    );
};

export default SafetyPopup;
