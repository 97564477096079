import VideoPlayer from '@/components/VideoPlayer/VideoPlayer';
import { LitPopupElement } from '@/custom-elements/LitPopupElement/LitPopupElement';
import { h } from 'preact';
import { render } from 'preact';
import React from 'react';

function init(container: HTMLElement | Document = document) {
    const videoPopups = Array.from(container.querySelectorAll<LitPopupElement>('.js-video-player'));

    videoPopups.forEach((videoPopup) => {
        const videoSrc = videoPopup.dataset.videoSrc;
        const name = videoPopup.dataset.litPopup;

        if (videoSrc) {
            const root = videoPopup.querySelector<HTMLElement>('.lit-popup-container');
            if (root) {
                render(<VideoPlayer src={videoSrc} name={name ?? 'video-player'} />, root);
            }
        }
    });
}
const _module = { init };

export default _module;
