import { h } from 'preact';
import React from 'react';

export const ReplaySvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.8995 13.35C20.248 17.6802 16.5117 21 12 21C7.02943 21 3 16.9705 3 12C3 7.02943 7.02943 3 12 3C15.6905 3 18.8623 5.22137 20.2511 8.4M16.5 8.4H20.46C20.7583 8.4 21 8.15823 21 7.86V3.9"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
