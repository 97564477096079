import { h, Fragment } from 'preact';
import React from 'react';
import { useCitiesAndTpsContext } from '../CitiesAndTpsContext';
import { motion, AnimatePresence } from 'framer-motion';
import { DoubleArrowRightSvg } from '../../svg/DoubleArrowRightSvg';
import { CloseIcon } from '../../svg/CloseIcon';
import { citiesData } from '@/data/cities';
import { tp } from '@/typograf';
import CountUp from 'react-countup';
import ClipReveal from '@/components/ClipReveal/ClipReveal';

const EXIT_DURATION = 0.5;

const ChooseTpsView = () => {
    const { setView, activeCitySlug, setActiveTpsSlug, tpsRevealDelay, tpsViewCloseTarget, setTpsRevealDelay } =
        useCitiesAndTpsContext();
    const data = citiesData.find((city) => city.slug === activeCitySlug);

    return (
        <Fragment>
            {data && (
                <Fragment>
                    <div className="city-and-tps-choose-city-header">
                        <motion.button
                            initial={{
                                opacity: 0,
                            }}
                            animate={{
                                opacity: 1,
                                transition: {
                                    delay: 0.67,
                                    duration: 0.67,
                                },
                            }}
                            exit={{
                                opacity: 0,
                                transition: {
                                    duration: EXIT_DURATION,
                                },
                            }}
                            onClick={() => {
                                setView(tpsViewCloseTarget);
                                setTpsRevealDelay(0);
                            }}
                            className="button button-close"
                        >
                            <div className="button__inner">
                                <CloseIcon />
                            </div>
                            <div className="button__inner button__inner-copy">
                                <CloseIcon />
                            </div>
                        </motion.button>
                    </div>
                    <div className="city-and-tps-choose-tps city">
                        <ClipReveal delay={tpsRevealDelay}>
                            <div className="city__name h3">{tp(data.name)}</div>
                        </ClipReveal>
                        <ClipReveal delay={tpsRevealDelay}>
                            <div className="city-value">
                                <div className="city__value-text text-sm">{tp('объем образования золошлаков')}</div>
                                <div className="city__value">
                                    <div className="h3">
                                        <CountUp start={0} decimal="," end={data.value} separator=" " />
                                    </div>
                                    <div className="h-text-md">тонн</div>
                                </div>
                            </div>
                        </ClipReveal>
                        <div className="city__tps">
                            {data.tps.map((item, i) => {
                                return (
                                    <ClipReveal key={i} delay={tpsRevealDelay}>
                                        <button
                                            className="button button-gray button-wide"
                                            disabled={item.disabled}
                                            onClick={() => {
                                                setActiveTpsSlug(item.slug);
                                                setView('tps');
                                            }}
                                        >
                                            <div className="button__inner">
                                                <div className="button__text text-md">{tp(item.name)}</div>
                                                <div className="button__icon">
                                                    <DoubleArrowRightSvg />
                                                </div>
                                            </div>
                                            <div className="button__inner button__inner-copy">
                                                <div className="button__text text-md">{tp(item.name)}</div>
                                                <div className="button__icon">
                                                    <DoubleArrowRightSvg />
                                                </div>
                                            </div>
                                        </button>
                                    </ClipReveal>
                                );
                            })}
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default ChooseTpsView;
