import { ITransitionPage } from '@barba/core';
import { landingEnterFromMap } from '@/animations/landing-page-animations';
import { mapLeaveToLanding } from '@/animations/map-page-animations';

export default {
    name: 'map-to-landing-transition',

    from: {
        namespace: 'map-page',
    },

    to: {
        namespace: 'landing-page',
    },

    enter() {
        window.scrollTo({ top: 0, behavior: 'auto' });
    },

    beforeLeave({ current }) {
        return new Promise((resolve) => {
            mapLeaveToLanding(current.container, resolve);
        });
    },

    afterEnter({ next }) {
        setTimeout(() => {
            landingEnterFromMap(next.container);
        }, 500);
    },
} as ITransitionPage;
