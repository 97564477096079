import { h } from 'preact';
import React from 'react';
import { render } from 'preact';
import CitiesAndTpsElement from '@/components/CitiesAndTpsElement/CitiesAndTpsElement';

function init(container: HTMLElement | Document = document) {
    const root = container.querySelector('.js-cities-and-tps-element');

    if (root) {
        render(<CitiesAndTpsElement />, root);
    }
}

function destroy(container: HTMLElement | Document = document) {
    const root = container.querySelector('.js-cities-and-tps-element');

    if (root) {
        render('', root);
    }
}

const _module = { init, destroy };

export default _module;
