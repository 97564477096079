import { ITransitionPage } from '@barba/core';
import { landingEnter } from '@/animations/landing-page-animations';

export default {
    name: 'landing-once-transition',

    to: {
        namespace: 'landing-page',
    },

    once({ next }) {
        setTimeout(() => {
            landingEnter(next.container);
        }, 500);
    },
} as ITransitionPage;
