import type { ProjectInfo } from '@/data/projects';
import { h } from 'preact';
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from 'react';

const SafetyContext = createContext<{
    activeProjectSlug: string;
    projects: ProjectInfo[];
    setActiveProjectSlug: Dispatch<SetStateAction<string>>;
    isAnimating: boolean;
    setIsAnimating: Dispatch<SetStateAction<boolean>>;
}>({
    activeProjectSlug: '',
    projects: [],
    setActiveProjectSlug: () => {},
    isAnimating: false,
    setIsAnimating: () => {},
});

export const SafetyContextProvider = ({ projects, children }: { projects: ProjectInfo[]; children: ReactNode }) => {
    const [activeProjectSlug, setActiveProjectSlug] = useState(projects[0]?.projectSlug || '');
    const [isAnimating, setIsAnimating] = useState(false);

    return (
        <SafetyContext.Provider
            value={{
                activeProjectSlug,
                projects,
                setActiveProjectSlug,
                isAnimating,
                setIsAnimating,
            }}
        >
            {children}
        </SafetyContext.Provider>
    );
};

export const useSafetyContext = () => useContext(SafetyContext);
